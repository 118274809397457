// SampleDataSection.js
import React from 'react';
import { Container, Grid, Box } from '@mui/material';
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ExcelDisplay from 'components/product/incomeByZipComponents/ExcelDisplay';
import MKBox from 'components/MKBox';


const SampleDataSection = ({ DownloadSample, targetSampleData, colors }) => (
    <MKBox component="section" py={6} sx={{ background: colors.medium }} ref={targetSampleData}>
        <Container maxWidth="lg">
            <MKTypography 
                variant="h2" 
                textAlign="center" 
                mb={4} 
                color="white"
                sx={{
                    fontSize: {
                        xs: '2rem',
                        lg: '2.5rem'
                    }
                }}
            >
                Preview Your Data Package
            </MKTypography>
            
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} lg={6}>
                    <MKBox p={{ xs: 2, lg: 3 }} sx={{ color: 'white' }}>
                        <MKTypography 
                            variant="h4" 
                            mb={3} 
                            color="white"
                            sx={{
                                fontSize: {
                                    xs: '1.5rem',
                                    lg: '2rem'
                                }
                            }}
                        >
                            What's Included in Your Download?
                        </MKTypography>
                        <MKTypography 
                            variant="body1" 
                            mb={4} 
                            color="white"
                            sx={{
                                fontSize: {
                                    xs: '0.9rem',
                                    lg: '1rem'
                                }
                            }}
                        >
                            Get a comprehensive Excel file containing 3 sheets of detailed income data organized by zip code.
                            Each metric includes both precise estimates and margin of error calculations, ensuring maximum accuracy. 
                            Download our free sample to preview the full dataset.
                        </MKTypography>
                        
                        <MKButton 
                            onClick={DownloadSample} 
                            variant="gradient" 
                            color="warning" 
                            size="large"
                            sx={{ 
                                fontSize: { xs: '1rem', lg: '1.1rem' },
                                width: '100%',
                                mb: 3,
                                py: { xs: 1.5, lg: 2 }
                            }}
                        >
                            Download Free Sample Data
                        </MKButton>
                        
                        <Box sx={{ mt: 3 }}>
                            <MKTypography 
                                variant="h5" 
                                mb={2} 
                                color="white"
                                sx={{
                                    fontSize: {
                                        xs: '1.25rem',
                                        lg: '1.5rem'
                                    }
                                }}
                            >
                                Key Data Points Include:
                            </MKTypography>
                            <ul style={{ 
                                color: 'white', 
                                paddingLeft: '20px',
                                fontSize: { xs: '0.9rem', lg: '1rem' }
                            }}>
                                <li>Median Household Income</li>
                                <li>Income Brackets Distribution</li>
                                <li>Retirement Income Statistics</li>
                                <li>Age-Specific Income Data</li>
                                <li>Margin of Error Values</li>
                            </ul>
                        </Box>
                    </MKBox>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <MKBox 
                        sx={{ 
                            background: 'white',
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                            mx: 'auto',
                            maxWidth: { xs: '100%', lg: '600px' }
                        }}
                    >
                        <MKTypography 
                            variant="h5" 
                            p={2} 
                            textAlign="center"
                            sx={{
                                fontSize: {
                                    xs: '1.25rem',
                                    lg: '1.5rem'
                                }
                            }}
                        >
                            Sample Data Preview
                        </MKTypography>
                        <ExcelDisplay />
                    </MKBox>
                </Grid>
            </Grid>
        </Container>
    </MKBox>
);

export default SampleDataSection;