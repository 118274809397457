import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import axios from 'axios';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';

const LandingMetricDisplay = ({ colors }) => {
    const displayColors = {
        mainCategory: '#ffffff',
        subCategory: '#ffffff',
        lowLevel: '#ffffff',
        hover: `${colors.primary}10`
    };
    const [metricsData, setMetricsData] = useState({
        DP02: { categories: {}, name: 'Social Characteristics' },
        DP03: { categories: {}, name: 'Economic Characteristics' },
        DP04: { categories: {}, name: 'Housing Characteristics' },
        DP05: { categories: {}, name: 'Demographic Characteristics' }
    });
    const [loading, setLoading] = useState(true);
    const [totalMetrics, setTotalMetrics] = useState({
        DP02: 0,
        DP03: 0,
        DP04: 0,
        DP05: 0
    });

    useEffect(() => {
        const fetchAllMetrics = async () => {
            try {
                const results = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/utils/metric-hierarchies-display/DP02-display`),
                    axios.get(`${process.env.REACT_APP_API_URL}/utils/metric-hierarchies-display/DP03-display`),
                    axios.get(`${process.env.REACT_APP_API_URL}/utils/metric-hierarchies-display/DP04-display`),
                    axios.get(`${process.env.REACT_APP_API_URL}/utils/metric-hierarchies-display/DP05-display`)
                ]);

                setTotalMetrics({
                    DP02: results[0].data.total_metrics || 0,
                    DP03: results[1].data.total_metrics || 0,
                    DP04: results[2].data.total_metrics || 0,
                    DP05: results[3].data.total_metrics || 0
                });

                setMetricsData({
                    DP03: { categories: results[1].data.metrics || {}, name: 'Economic Characteristics' },
                    DP04: { categories: results[2].data.metrics || {}, name: 'Housing Characteristics' },
                    DP05: { categories: results[3].data.metrics || {}, name: 'Demographic Characteristics' },
                    DP02: { categories: results[0].data.metrics || {}, name: 'Social Characteristics' }
                });
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchAllMetrics();
    }, []);

    // Get parent categories (those with is_parent = true)
    const getParentCategories = (categories) => {
        if (!categories || typeof categories !== 'object') {
            return [];
        }
        return Object.values(categories)
            .filter(cat => cat && cat.is_parent);
    };

    const renderChildMetrics = (metric, categories, level = 0) => {
        if (!metric || !metric.id) return null;

        const children = metric.children
            ? metric.children
                .map(childId => categories[childId])
                .filter(child => child)
            : [];

        return (
            <Box key={metric.id}>
                <MKTypography 
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        p: 0.75,
                        pl: level * 1.5 + 1,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.875rem',
                        lineHeight: 1.2,
                        fontWeight: metric.is_low_level ? 400 : 600,
                        borderBottom: '1px solid rgba(0,0,0,0.03)',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            backgroundColor: displayColors.hover,
                            transform: 'translateX(4px)'
                        }
                    }}
                >
                    {metric.is_low_level ? (
                        <CircleIcon sx={{ 
                            mr: 0.75,
                            fontSize: '4px',
                            color: 'text.secondary',
                            opacity: 0.5
                        }} />
                    ) : (
                        <ArrowRightIcon sx={{ 
                            mr: 0.75,
                            fontSize: '1rem',
                            color: 'text.secondary',
                            opacity: 0.7
                        }} />
                    )}
                    {metric.label}
                </MKTypography>
                {children.length > 0 && children.map(child => 
                    renderChildMetrics(child, categories, level + 1)
                )}
            </Box>
        );
    };

    const renderParentMetric = (metric, categories) => {
        if (!metric || !metric.id) return null;

        const children = metric.children
            ? metric.children
                .map(childId => categories[childId])
                .filter(child => child)
            : [];

        return (
            <Accordion 
                key={metric.id}
                disableGutters
                sx={{ 
                    boxShadow: 'none',
                    '&:before': { display: 'none' },
                    backgroundColor: 'white',
                    mb: 0,
                    borderBottom: '1px solid rgba(0,0,0,0.05)',
                    '&:last-child': {
                        borderBottom: 'none'
                    },
                    '& .MuiAccordionDetails-root': {
                        padding: 0,
                        margin: 0
                    },
                    '& .MuiCollapse-root': {
                        margin: 0
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        p: 0.75,
                        minHeight: 0,
                        '& .MuiAccordionSummary-content': {
                            margin: 0,
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            width: '100%'
                        },
                        '&:hover': {
                            backgroundColor: displayColors.hover
                        }
                    }}
                >
                    <MKTypography 
                        variant="h6"
                        color={colors.dark}
                        sx={{
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            maxWidth: '80%',
                        }}
                    >
                        {metric.label.replace(/LABEL\d+/, '')}
                    </MKTypography>
                    <MKTypography 
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            fontSize: '0.875rem',
                            opacity: 0.7,
                            ml: 1,
                            whiteSpace: 'nowrap',
                            flexShrink: 0
                        }}
                    >
                        {metric.total_children} metrics
                    </MKTypography>
                </AccordionSummary>
                <AccordionDetails sx={{ 
                    p: 0, 
                    backgroundColor: 'white',
                    borderTop: '1px solid rgba(0,0,0,0.05)'
                }}>
                    {children.map(child => renderChildMetrics(child, categories, 1))}
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <MKBox component="section" py={6}>
            <Container>
                <MKTypography 
                    variant="h2" 
                    textAlign="center" 
                    mb={2}
                    color={colors.primary}
                >
                    Available Metrics
                </MKTypography>
                <MKTypography 
                    variant="body1" 
                    textAlign="center" 
                    mb={6} 
                    color="text.secondary"
                >
                    Explore our comprehensive collection of {Object.values(totalMetrics).reduce((a, b) => a + b, 0)} census metrics across four main categories
                </MKTypography>
                
                {loading ? (
                    <MKTypography textAlign="center">Loading metrics...</MKTypography>
                ) : (
                    <Grid container spacing={4}>
                        {/* Left Column */}
                        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                            {Object.entries(metricsData)
                                .slice(0, 2) // Take first two categories
                                .map(([key, { categories, name }]) => (
                                    <MKBox 
                                        key={key}
                                        mb={4} 
                                        sx={{
                                            backgroundColor: colors.primary,
                                            borderRadius: '8px',
                                            boxShadow: `0 4px 12px ${colors.primary}15`,
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <MKTypography 
                                            variant="h4" 
                                            p={2} 
                                            fontWeight="bold"
                                            color="white"
                                            sx={{
                                                borderBottom: '2px solid',
                                                borderColor: colors.secondary,
                                                backgroundColor: 'white'
                                            }}
                                        >
                                            {name}
                                        </MKTypography>
                                        <Box>
                                            {getParentCategories(categories).map(category => 
                                                renderParentMetric(category, categories)
                                            )}
                                        </Box>
                                    </MKBox>
                                ))}
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                            {Object.entries(metricsData)
                                .slice(2) // Take last two categories
                                .map(([key, { categories, name }]) => (
                                    <MKBox 
                                        key={key}
                                        mb={4} 
                                        sx={{
                                            backgroundColor: colors.primary,
                                            borderRadius: '8px',
                                            boxShadow: `0 4px 12px ${colors.primary}15`,
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <MKTypography 
                                            variant="h4" 
                                            p={2} 
                                            color="white"
                                            fontWeight="bold"
                                            sx={{
                                                borderBottom: '2px solid',
                                                borderColor: colors.secondary,
                                                backgroundColor: 'white'
                                            }}
                                        >
                                            {name}
                                        </MKTypography>
                                        <Box>
                                            {getParentCategories(categories).map(category => 
                                                renderParentMetric(category, categories)
                                            )}
                                        </Box>
                                    </MKBox>
                                ))}
                        </Grid>
                    </Grid>
                )}
            </Container>
        </MKBox>
    );
};

LandingMetricDisplay.propTypes = {
    colors: PropTypes.object.isRequired
};

export default LandingMetricDisplay; 