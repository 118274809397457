import React, { useState } from "react";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { jwtDecode } from "jwt-decode";


const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let navigate = useNavigate();
  const signIn = useSignIn();
  const signOut = useSignOut();

  const [loginFormData, setLoginFormData] = React.useState({ email: '', password: '' });

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('username', loginFormData.email); // The OAuth2PasswordRequestForm expects 'username'
    formData.append('password', loginFormData.password);

    axios.post(`${process.env.REACT_APP_API_URL}/login/access-token`, formData)
      .then(res => {
        setIsLoading(false);
        const token = res.data.access_token;
        const decoded = jwtDecode(token);
        if (res.status === 200) {
          signIn({
            auth: {
              token: token,
              staff: decoded.staff
            },
            userState: {
              staff: decoded.staff,
              email_verified: decoded.email_verified,
              email: decoded.email
            }
          });

          if (decoded.email_verified !== true) {
            signOut();
            navigate('/user/verify-email/check-email');
          } else {
            navigate('/account');
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        setShowAlert(true);
        if (error.response && error.response.status === 400) {
          setErrorMessage("Incorrect email or password");
        } else {
          setErrorMessage("An unexpected error occurred");
        }
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e);
    }
  };

  return (
    <>

      <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
        <MKBox width="100%" mx="auto" pt={5}>
          <Grid container spacing={1} rowSpacing={5} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-3}
                  pt={2.5}
                  pb={2.875}
                  px={2.5}
                  textAlign="center"
                  lineHeight={1}
                >
                  <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Sign in
                  </MKTypography>
                  <MKTypography variant="button" color="white">
                    Welcome back
                  </MKTypography>
                </MKBox>
                {showAlert &&
                  <MKBox
                    variant="gradient"
                    bgColor="secondary"
                    borderRadius="lg"
                    mx={2}
                    mt={3}
                    pt={2}
                    pb={2}
                    px={2.5}
                    textAlign="center"
                    lineHeight={1}
                  >
                    <MKTypography variant="button" color="white">
                      {errorMessage}
                    </MKTypography>
                  </MKBox>
                }
                <MKBox p={3}>
                  <MKBox component="form" role="form" onSubmit={onSubmit}>
                    <MKBox mb={2}>
                      <MKInput
                        type="email"
                        label="Email"
                        fullWidth
                        value={loginFormData.email}
                        onChange={(e) => setLoginFormData({ ...loginFormData, email: e.target.value })}
                        onKeyDown={handleKeyDown}
                        required
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        name="password"
                        sx={{ width: '85%' }}
                        value={loginFormData.password}
                        onChange={(e) => setLoginFormData({ ...loginFormData, password: e.target.value })}
                        onKeyDown={handleKeyDown}
                        required
                      />
                      <MKButton
                        onClick={toggleShowPassword}
                        iconOnly
                      >
                        {showPassword ? <EyeFill size={100} /> : <EyeSlashFill size={100} />}
                      </MKButton>
                    </MKBox>

                    <MKBox mt={2} mb={1}>
                      <MKButton variant="gradient" color="info" fullWidth type="submit" disabled={isLoading}>
                        {isLoading ? 'Signing in...' : 'Sign in'}
                      </MKButton>
                    </MKBox>
                    <MKBox mt={1} textAlign="center">
                      <MKTypography variant="button" color="text">
                        Don&apos;t have an account?{" "}
                        <MKTypography
                          component={Link}
                          to="/user/sign-up"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Sign up
                        </MKTypography>
                      </MKTypography>
                      <MKTypography variant="button" color="text">
                        <br></br>
                        <MKTypography
                          component={Link}
                          to="/user/reset"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          Forgot Password?
                        </MKTypography>
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </>
  );
};

export default Login;
