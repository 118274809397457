import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import formatNumber from '../../utils/formater';
import StateSVGComponent from 'components/images/StateSVGs';
import DataTable from 'components/product/DataTable';

const makeStatePretty = (stateName) => {
    if (stateName === 'usa') {
        return ' the USA';
    }
    return stateName
        .replace(/_/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase()) || 'your state';
};

const SpecificStateDataBottom = ({ stateName, stateData }) => {
    const [incomeDistributionTableData, setIncomeDistributionTableData] = useState([]);
    const [ageDistributionTableData, setAgeDistributionTableData] = useState([]);
    const [earningTypeTableData, setEarningTypeTableData] = useState([]);

    useEffect(() => {
        const transformedData = [
            ["Total HH count", Number(stateData?.data?.B19001_001E), ""],
            ["Less than $10,000", Number(stateData?.data?.B19001_002E), `${(Number(stateData?.data?.B19001_002E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$10,000 to $14,999", Number(stateData?.data?.B19001_003E), `${(Number(stateData?.data?.B19001_003E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$15,000 to $19,999", Number(stateData?.data?.B19001_004E), `${(Number(stateData?.data?.B19001_004E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$20,000 to $24,999", Number(stateData?.data?.B19001_005E), `${(Number(stateData?.data?.B19001_005E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$25,000 to $29,999", Number(stateData?.data?.B19001_006E), `${(Number(stateData?.data?.B19001_006E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$30,000 to $34,999", Number(stateData?.data?.B19001_007E), `${(Number(stateData?.data?.B19001_007E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$35,000 to $39,999", Number(stateData?.data?.B19001_008E), `${(Number(stateData?.data?.B19001_008E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$40,000 to $44,999", Number(stateData?.data?.B19001_009E), `${(Number(stateData?.data?.B19001_009E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$45,000 to $49,999", Number(stateData?.data?.B19001_010E), `${(Number(stateData?.data?.B19001_010E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$50,000 to $59,999", Number(stateData?.data?.B19001_011E), `${(Number(stateData?.data?.B19001_011E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$60,000 to $74,999", Number(stateData?.data?.B19001_012E), `${(Number(stateData?.data?.B19001_012E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$75,000 to $99,999", Number(stateData?.data?.B19001_013E), `${(Number(stateData?.data?.B19001_013E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$100,000 to $124,999", Number(stateData?.data?.B19001_014E), `${(Number(stateData?.data?.B19001_014E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$125,000 to $149,999", Number(stateData?.data?.B19001_015E), `${(Number(stateData?.data?.B19001_015E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$150,000 to $199,999", Number(stateData?.data?.B19001_016E), `${(Number(stateData?.data?.B19001_016E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
            ["$200,000 or more", Number(stateData?.data?.B19001_017E), `${(Number(stateData?.data?.B19001_017E)/Number(stateData?.data?.B19001_001E)*100).toFixed(2)}%`],
        ];
        setIncomeDistributionTableData(transformedData);
        const ageData = [
            ["Age 15-24", `$${formatNumber(Number(stateData?.data?.S1903_C03_011E))}`],
            ["Age 25-44", `$${formatNumber(Number(stateData?.data?.S1903_C03_012E))}`],
            ["Age 45-64", `$${formatNumber(Number(stateData?.data?.S1903_C03_013E))}`],
            ["Age 65+", `$${formatNumber(Number(stateData?.data?.S1903_C03_014E))}`],
            ["Female HH, No Spouse Present", `$${formatNumber(Number(stateData?.data?.S1903_C03_020E))}`],
            ["Male HH, No Spouse Present", `$${formatNumber(Number(stateData?.data?.S1903_C03_022E))}`],
        ];
        setAgeDistributionTableData(ageData);

        const earningTypeData = [
            ["Any Income", Number(stateData?.data?.B19051_002E), Number(stateData?.data?.B19051_003E)],
            ["Wage Or Salary", Number(stateData?.data?.B19052_002E), Number(stateData?.data?.B19052_003E)],
            ["Retirement Income", Number(stateData?.data?.B19059_002E), Number(stateData?.data?.B19059_003E)],
            ["Self-Employment", Number(stateData?.data?.B19053_002E), Number(stateData?.data?.B19053_003E)],
            ["Interest, Dividends, Or Net Rental Income", Number(stateData?.data?.B19054_002E), Number(stateData?.data?.B19054_003E)],
            ["Social Security Income", Number(stateData?.data?.B19055_002E), Number(stateData?.data?.B19055_003E)],
            ["Supplemental Security Income (Ssi)", Number(stateData?.data?.B19056_002E), Number(stateData?.data?.B19056_003E)],
            ["Public Assistance Income", Number(stateData?.data?.B19057_002E), Number(stateData?.data?.B19057_003E)],
            ["Cash Public Assistance Or Food Stamps/Snap", Number(stateData?.data?.B19058_002E), Number(stateData?.data?.B19058_003E)],
        ];
        setEarningTypeTableData(earningTypeData);
    }, [stateData]);

    return (
        <MKBox 
            component="section" 
            sx={{ 
                py: { xs: 1 },
                background: '#ffffff',
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} textAlign='Center'>
                        <MKTypography variant="h4" sx={{ color: '#2c3e50', fontWeight: 600}}>
                            Understanding Income Distribution in {makeStatePretty(stateName)}
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 2}}>
                        <Box sx={{ mb: 4 , textAlign: 'Left'}}>
                        <MKTypography variant="body1" sx={{ color: '#2c3e50', fontSize: '14px', mb: 2}}>
                            The income distribution table on the right provides a detailed breakdown of household earnings across various income brackets within {makeStatePretty(stateName)}. This data is like our reports is sourced from the latest available census data.
                            By analyzing these income brackets, businesses, Marketers, stakeholders, and researchers can gain insights into the economic landscape of specific ZIP codes.
                            {/* This insight leads to more informed decisions that align with the economic realities of each ZIP code, ultimately driving more effective and targeted outcomes. */}
                        </MKTypography>
                            <MKTypography variant="h5" sx={{ color: '#2c3e50', mb: 2 }}>
                                Targeting High Earners:
                                    <MKTypography variant="body1" sx={{ color: '#2c3e50', fontSize: '14px'}}>
                                    ZIP codes with a higher concentration of households in the upper income brackets (e.g., $100,000 and above) can be targeted for luxury goods, premium services, and investment opportunities. These areas often indicate a higher disposable income, making them ideal for businesses looking to expand their market reach.
                                    </MKTypography>
                            </MKTypography>

                            <MKTypography variant="h5" sx={{ color: '#2c3e50', mb: 2 }}>
                                Supporting Lower Earners:
                                    <MKTypography variant="body1" sx={{ color: '#2c3e50' , fontSize: '14px'}}>
                                        Conversely, ZIP codes with a significant number of households in the lower income brackets (e.g., less than $30,000) may benefit from community support programs, affordable housing initiatives, and economic development projects. Understanding these areas can help in tailoring social services and policy interventions to uplift economically disadvantaged communities.
                                    </MKTypography>
                            </MKTypography>
                            <MKTypography variant="h5" sx={{ color: '#2c3e50', mb: 2 }}>
                                Strategic Planning:
                                    <MKTypography variant="body1" sx={{ color: '#2c3e50' , fontSize: '14px'}}>
                                    Businesses can use this data to optimize their location strategies, ensuring they are positioned to meet the needs of their target demographics.
                                    For example,real estate professionals, understanding income distribution can guide investment decisions, helping to identify emerging markets or areas with potential for growth.
                                    </MKTypography>
                            </MKTypography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} textAlign='Center'>
                        <MKTypography variant="h4" sx={{ color: '#2c3e50', fontWeight: 600, mb: 2 }}>
                            Income Distribution of {makeStatePretty(stateName)}
                        </MKTypography>
                        {incomeDistributionTableData.length > 0 ? (
                            <DataTable lists={incomeDistributionTableData} headers={["Income Range", "Count of Households", "Percentage"]} />
                        ) : (
                            <Box sx={{ textAlign: 'center', p: 3 }}>Loading data...</Box>
                        )} 
                </Grid>
                <Grid container spacing={4}>
                    </Grid>
                    <Grid item s={12} md={6} sx={{ mb: 2}}>
                        <Box sx={{ mb: 4 , textAlign: 'Left'}}>
                        <MKTypography variant="h4" sx={{ color: '#2c3e50', fontWeight: 600, mb: 2 }}>
                            Age & Gender Distribution of {makeStatePretty(stateName)}
                        </MKTypography>
                            {ageDistributionTableData.length > 0 ? (
                                <DataTable lists={ageDistributionTableData} headers={["Age Range", "Average House Hold Income"]} />
                            ) : (
                                <Box sx={{ textAlign: 'center', p: 3 }}>Loading data...</Box>
                            )} 
                        </Box>
                    </Grid>
                    <Grid item s={12} md={6} textAlign='Center'>
                        <Box sx={{ mb: 4 , textAlign: 'Left'}}>
                            <MKTypography variant="h5" sx={{ color: '#2c3e50', mb: 2 }}>
                                Age & Gender-Based Mean Income Distributions
                                <MKTypography variant="body1" sx={{ color: '#2c3e50', fontSize: '14px'}}>
                                    The income distribution across different age groups reveals significant insights into economic opportunities and consumer behavior. 
                                    Young adults aged 15-24 in {makeStatePretty(stateName)} have an average income of <strong style={{color: '#538933'}}>${formatNumber(Number(stateData?.data?.S1903_C03_011E))}</strong>. 
                                    This demographic can be effectively targeted with affordable products, educational opportunities, and career development programs to support their growth and increase their earning potential.
                                    <br></br>
                                    In contrast, individuals aged 45-64 in {makeStatePretty(stateName)} enjoy the higher average income at <strong style={{color: '#538933'}}>${formatNumber(Number(stateData?.data?.S1903_C03_012E))}</strong>. 
                                    This group is ideal for targeting with premium products, investment opportunities, and retirement planning services. As individuals transition into retirement, 
                                    the average income for those aged 65 and older is <strong style={{color: '#538933'}}>${formatNumber(Number(stateData?.data?.S1903_C03_014E))}</strong>, these idividuals are ideal for things such as healthcare, leisure, and lifestyle offerings.
                                </MKTypography>
                            </MKTypography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item s={12} md={6} sx={{ mb: 2}}>
                        <Box sx={{ mb: 4 , textAlign: 'Left'}}>
                        <MKTypography variant="h5" sx={{ color: '#2c3e50', mb: 2 }}>
                        General Analysis of Household Income Types
                            <MKTypography variant="body1" sx={{ color: '#2c3e50', fontSize: '14px'}}>
                            The data on household income types provides insights into the economic landscape, revealing trends that inform strategic decisions. 
                            By analyzing income distribution, businesses and policymakers can guide economic initiatives, workforce planning, and support programs. 
                            Understanding reliance on different income sources helps tailor services and policies to support disadvantaged communities.
                            </MKTypography>
                        </MKTypography>
                        <MKTypography variant="h5" sx={{ color: '#2c3e50', mb: 2 }}>
                        Targeting Households with Retirement and Social Security Income
                            <MKTypography variant="body1" sx={{ color: '#2c3e50', fontSize: '14px'}}>
                            Households receiving retirement and social security income represent a crucial demographic for finding individual in need of financial services and support. 
                            This data can be instrumental for businesses and marketing teams aiming to develop products and strategies that cater to the unique needs of retirees. 
                            By identifying areas with a high concentration of retirement income , organizations can tailor offerings such as annuities, retirement planning, and healthcare solutions to ensure retirees have access to the resources 
                            necessary for a secure and comfortable lifestyle.
                            </MKTypography>
                        </MKTypography>
                        </Box>
                    </Grid>
                    <Grid item s={12} md={6} textAlign='Center'>
     
                            <Box sx={{ mb: 4 , textAlign: 'Left'}}>
                            {earningTypeTableData.length > 0 ? (
                                <DataTable lists={earningTypeTableData} headers={["Household Income Types", "Household With", "Household Without"]} />
                            ) : (
                                <Box sx={{ textAlign: 'center', p: 3 }}>Loading data...</Box>
                            )} 
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default SpecificStateDataBottom;