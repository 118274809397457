import React from 'react';
import formatNumber from '../utils/formater';

function DataTable({ lists, headers }) {
    
    
    const tableStyles = {
        header: {
            backgroundColor: '#f4f4f4',
            color: '#333',
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'bold',
            fontSize: '16px',
            textAlign: 'center'
        },
        row: {
            fontFamily: 'Arial, sans-serif',
            fontSize: '13px',
            color: '#000000',
            textAlign: 'center'
        }
    };
    

    return (
        <div style={{ overflowX: 'auto' }}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th
                                key={index}
                                style={{
                                    textAlign: index === 0 ? 'left' : 'right',
                                    padding: '2px',
                                    borderBottom: '1px solid #ddd',
                                    ...tableStyles.header
                                }}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {lists.map((row, index) => (
                        <tr 
                            key={index}
                            style={{
                                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9'
                            }}
                        >
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    style={{
                                        textAlign: cellIndex === 0 ? 'left' : 'right',
                                        padding: '2px',
                                        borderBottom: '1px solid #ddd',
                                        ...tableStyles.row
                                    }}
                                >
                                    {cell !== undefined ? formatNumber(cell) : ''}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DataTable;


