import USA from "../../assets/images/state_median_income/Usa.webp"
import USA_RECTANGLE from "../../assets/images/state_median_income/Usa_rectangle.webp"

import ALABAMA from "../../assets/images/no_background/alabama-income-distribution-by-zipcode.png"
import ALASKA from "../../assets/images/no_background/alaska-income-distribution-by-zipcode.png"
import ARIZONA from "../../assets/images/no_background/arizona-income-distribution-by-zipcode.png"
import ARKANSAS from "../../assets/images/no_background/arkansas-income-distribution-by-zipcode.png"
import CALIFORNIA from "../../assets/images/no_background/california-income-distribution-by-zipcode.png"
import COLORADO from "../../assets/images/no_background/colorado-income-distribution-by-zipcode.png"
import CONNECTICUT from "../../assets/images/no_background/connecticut-income-distribution-by-zipcode.png"
import DELAWARE from "../../assets/images/no_background/delaware-income-distribution-by-zipcode.png"
import FLORIDA from "../../assets/images/no_background/florida-income-distribution-by-zipcode.png"
import GEORGIA from "../../assets/images/no_background/georgia-income-distribution-by-zipcode.png"
import HAWAII from "../../assets/images/no_background/hawaii-income-distribution-by-zipcode.png"
import IDAHO from "../../assets/images/no_background/idaho-income-distribution-by-zipcode.png"
import ILLINOIS from "../../assets/images/no_background/illinois-income-distribution-by-zipcode.png"
import INDIANA from "../../assets/images/no_background/indiana-income-distribution-by-zipcode.png"
import IOWA from "../../assets/images/no_background/iowa-income-distribution-by-zipcode.png"
import KANSAS from "../../assets/images/no_background/kansas-income-distribution-by-zipcode.png"
import KENTUCKY from "../../assets/images/no_background/kentucky-income-distribution-by-zipcode.png"
import LOUISIANA from "../../assets/images/no_background/louisiana-income-distribution-by-zipcode.png"
import MAINE from "../../assets/images/no_background/maine-income-distribution-by-zipcode.png"
import MARYLAND from "../../assets/images/no_background/maryland-income-distribution-by-zipcode.png"
import MASSACHUSETTS from "../../assets/images/no_background/massachusetts-income-distribution-by-zipcode.png"
import MICHIGAN from "../../assets/images/no_background/michigan-income-distribution-by-zipcode.png"
import MINNESOTA from "../../assets/images/no_background/minnesota-income-distribution-by-zipcode.png"
import MISSISSIPPI from "../../assets/images/no_background/mississippi-income-distribution-by-zipcode.png"
import MISSOURI from "../../assets/images/no_background/missouri-income-distribution-by-zipcode.png"
import MONTANA from "../../assets/images/no_background/montana-income-distribution-by-zipcode.png"
import NEBRASKA from "../../assets/images/no_background/nebraska-income-distribution-by-zipcode.png"
import NEVADA from "../../assets/images/no_background/nevada-income-distribution-by-zipcode.png"
import NEW_HAMPSHIRE from "../../assets/images/no_background/new_hampshire-income-distribution-by-zipcode.png"
import NEW_JERSEY from "../../assets/images/no_background/new_jersey-income-distribution-by-zipcode.png"
import NEW_MEXICO from "../../assets/images/no_background/new_mexico-income-distribution-by-zipcode.png"
import NEW_YORK from "../../assets/images/no_background/new_york-income-distribution-by-zipcode.png"
import NORTH_CAROLINA from "../../assets/images/no_background/north_carolina-income-distribution-by-zipcode.png"
import NORTH_DAKOTA from "../../assets/images/no_background/north_dakota-income-distribution-by-zipcode.png"
import OHIO from "../../assets/images/no_background/ohio-income-distribution-by-zipcode.png"
import OKLAHOMA from "../../assets/images/no_background/oklahoma-income-distribution-by-zipcode.png"
import OREGON from "../../assets/images/no_background/oregon-income-distribution-by-zipcode.png"
import PENNSYLVANIA from "../../assets/images/no_background/pennsylvania-income-distribution-by-zipcode.png"
import RHODE_ISLAND from "../../assets/images/no_background/rhode_island-income-distribution-by-zipcode.png"
import SOUTH_CAROLINA from "../../assets/images/no_background/south_carolina-income-distribution-by-zipcode.png"
import SOUTH_DAKOTA from "../../assets/images/no_background/south_dakota-income-distribution-by-zipcode.png"
import TENNESSEE from "../../assets/images/no_background/tennessee-income-distribution-by-zipcode.png"
import TEXAS from "../../assets/images/no_background/texas-income-distribution-by-zipcode.png"
import UTAH from "../../assets/images/no_background/utah-income-distribution-by-zipcode.png"
import VERMONT from "../../assets/images/no_background/vermont-income-distribution-by-zipcode.png"
import VIRGINIA from "../../assets/images/no_background/virginia-income-distribution-by-zipcode.png"
import WASHINGTON from "../../assets/images/no_background/washington-income-distribution-by-zipcode.png"
import WEST_VIRGINIA from "../../assets/images/no_background/west_virginia-income-distribution-by-zipcode.png"
import WISCONSIN from "../../assets/images/no_background/wisconsin-income-distribution-by-zipcode.png"
import WYOMING from "../../assets/images/no_background/wyoming-income-distribution-by-zipcode.png"

const state_median_income = {
    "alabama":ALABAMA,
    "alaska":ALASKA,
    "arizona":ARIZONA,
    "arkansas":ARKANSAS,
    "california":CALIFORNIA,
    "colorado":COLORADO,
    "connecticut":CONNECTICUT,
    "delaware":DELAWARE,
    "florida":FLORIDA,
    "georgia":GEORGIA,
    "hawaii":HAWAII,
    "idaho":IDAHO,
    "illinois":ILLINOIS,
    "indiana":INDIANA,
    "iowa":IOWA,
    "kansas":KANSAS,
    "kentucky":KENTUCKY,
    "louisiana":LOUISIANA,
    "maine":MAINE,
    "maryland":MARYLAND,
    "massachusetts":MASSACHUSETTS,
    "michigan":MICHIGAN,
    "minnesota":MINNESOTA,
    "mississippi":MISSISSIPPI,
    "missouri":MISSOURI,
    "montana":MONTANA,
    "nebraska":NEBRASKA,
    "nevada":NEVADA,
    "new_hampshire":NEW_HAMPSHIRE,
    "new_jersey":NEW_JERSEY,
    "new_mexico":NEW_MEXICO,
    "new_york":NEW_YORK,
    "north_carolina":NORTH_CAROLINA,
    "north_dakota":NORTH_DAKOTA,
    "ohio":OHIO,
    "oklahoma":OKLAHOMA,
    "oregon":OREGON,
    "pennsylvania":PENNSYLVANIA,
    "rhode_island":RHODE_ISLAND,
    "south_carolina":SOUTH_CAROLINA,
    "south_dakota":SOUTH_DAKOTA,
    "tennessee":TENNESSEE,
    "texas":TEXAS,
    "usa":USA,
    "utah":UTAH,
    "vermont":VERMONT,
    "virginia":VIRGINIA,
    "washington":WASHINGTON,
    "west_virginia":WEST_VIRGINIA,
    "wisconsin":WISCONSIN,
    "wyoming":WYOMING,
    "usa_rectangle":USA_RECTANGLE,
}

export const StateImage = (imageName) => {
    const state_name = imageName.trim().toLowerCase().replace(/ /g, '_');
    return state_median_income[state_name]
};

export default StateImage


