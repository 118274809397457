import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import CardContent from '@mui/material/CardContent';
import MKBox from "../MKBox";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { capitalizeAndReplaceUnderscores } from './ProductUtils';  // Assume this utility is imported correctly
import DownloadPurchasedProduct from './DownloadPurchsedProduct';
import StateImage from 'components/images/StateImages';
import CircularProgress from '@mui/material/CircularProgress';

const PurchasedProductsView = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const authHeader = useAuthHeader();
          
  useEffect(() => {
    const config = {
      headers: {
        Authorization: authHeader
      }
    };
 
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/products/purchased-products`, config)
      .then(response => {
        setProducts(response.data.data);
      })
      .catch(error => {
        setError(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authHeader]);

  return (
    <div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
          <CircularProgress />
        </div>
      ) : products.length > 0 ? (
        <div className="d-flex flex-wrap justify-content-between">
          {products.map((product, index) => (
            <Grid container spacing={2} key={`${product.id}-${index}`}>
              <Grid item xs={.2} md={.2}></Grid>
              <Grid item xs={6} md={4}>
                <MKBox position="relative" borderRadius="lg" mx={2} mt={2} sx={{ background: 'white' }}>
                  <MKBox
                    component="img"
                    src={StateImage(product.product_name)}
                    alt={product.product_name}
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    height="200px"
                    position="relative"
                    zIndex={1}
                    sx={{ objectFit: "contain", backgroundColor: "#f5f5f5" }}
                  />
                  <MKBox
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={3} md={4}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {product.product_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {capitalizeAndReplaceUnderscores(product.product_type)} {product.product_year}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={.6} md={.2}></Grid>
              <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DownloadPurchasedProduct id={product.id} />
              </Grid>
            </Grid>
          ))}
        </div>
      ) : (
        <>
        <Typography variant="h6" sx={{ textAlign: 'center', margin: '20px' }}>
          You have not yet purchased any products.
        </Typography>
        </>
      )}
    </div>
  );
};

export default PurchasedProductsView;
