import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import formatNumber from '../../utils/formater';
import StateSVGComponent from 'components/images/StateSVGs';

const makeStatePretty = (stateName) => {
    if (stateName === 'usa') {
        return ' the USA';
    }
    return stateName
        .replace(/_/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase()) || 'your state';
};

const SpecificStateData = ({ stateName, stateData }) => {
    return (
        <MKBox 
            component="section" 
            sx={{ 
                py: { xs: 2, lg: 4 },
                background: '#ffffff',
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ mb: { xs: 2 }, textAlign: 'center' }}>
                    <MKTypography
                        variant="h2"
                        sx={{
                            color: '#2c3e50',
                            fontWeight: 700,
                            mb: 2,
                            fontSize: {
                                xs: '2rem',
                                lg: '2.5rem'
                            }
                        }}
                    >
                        {makeStatePretty(stateName)} Income Data Summary
                    </MKTypography>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ 
                            position: 'relative',
                            width: '100%',
                            height: '600px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            {/* SVG Container */}
                            <Box sx={{ 
                                position: 'relative',
                                width: '90%',
                                height: '90%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <StateSVGComponent 
                                    stateName={stateName} 
                                    size="100%" 
                                    color="#90EE90"
                                />

                                {/* Stats Overlay */}
                                <Box sx={{ 
                                    width: '80%',
                                    zIndex: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    mt: '-15%'
                                }}>
                                    <MKTypography variant="body1" sx={{ fontWeight: 600 }}>
                                    ↪ The Mean Income in {makeStatePretty(stateName)} is ${formatNumber(Number(stateData?.data?.S1901_C01_013E))}
                                    </MKTypography>
                                    <MKTypography variant="body1" sx={{ fontWeight: 600 }}>
                                    ↪ The Median Income in {makeStatePretty(stateName)} is ${formatNumber(Number(stateData?.data?.S1901_C01_012E))}
                                    </MKTypography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item s={12} md={6}>
                        <Box sx={{ mb: 4 }}>
                            <Box sx={{ 
                                p: 3, 
                                background: '#f8f9fa', 
                                borderRadius: '12px',
                                border: '1px solid #e9ecef'
                            }}>
                                <MKTypography variant="body1" sx={{ color: '#2c3e50', mb: 2 }}>
                                    OurIncome Report offers over 98 income related metrics by Zip Code. Trying to find the right data inside? Our data list provides things such as 
                                </MKTypography>
                                    <ul>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Average income by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Median income by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Amount of Households by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Amount of Retirement households by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Amount of Wage or Salary households by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Amount of Self-Employment households by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                        <li>
                                            <MKTypography variant="body2" sx={{ fontSize: '0.875rem' }}>
                                                The Amount of Social Security households by Zip Code in {makeStatePretty(stateName)}
                                            </MKTypography>
                                        </li>
                                    </ul>
                                <MKTypography variant="body1" sx={{ color: '#2c3e50', mb: 2 }}>
                                Whether you're looking for state-specific data, national income trends, or granular ZIP code statistics, we provide all the tools you need to access and analyze income data effortlessly.
                                </MKTypography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default SpecificStateData;
