// Contact.js

import React, { useState } from 'react';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { Link } from 'react-router-dom';
import axios from 'axios'
import { Helmet } from 'react-helmet';

import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


const Contact = () => {
  const [missingData, setMissingData] = useState(false); 
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    company_name: '',
    request_data: '',
    request_type: 'Contact-Us',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMissingData(false);

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.first_name || !formData.email || !formData.request_data) {
      setMissingData(true);
      return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/requests/contact_request`, formData)
      .then((res) => {
        setMissingData(false);
        setSubmitted(true);
      })
      .catch(error => {
        setMissingData(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | TerraGrasp</title>
        <meta name="description" content="Get in touch with TerraGrasp for inquiries and support." />
        <meta property="og:title" content="Contact Us | TerraGrasp" />
        <meta property="og:description" content="Reach out to TerraGrasp for any questions or support." />
        <meta property="og:image" content={`${window.location.origin}/logo_small.webp`} />
        <meta name="twitter:title" content="Contact Us | TerraGrasp" />
        <meta name="twitter:description" content="Contact TerraGrasp for inquiries." />
        <meta name="twitter:image" content={`${window.location.origin}/logo_small.webp`} />
      </Helmet>
      {submitted ? (
        <SuccessMessage />
      ) : (
        <ContactForm 
          formData={formData} 
          handleChange={handleChange} 
          handleSubmit={handleSubmit}
          missingData={missingData}
        />
      )}
    </>
  );
};



const SuccessMessage = () => (
  <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
  <MKBox width="100%" mx="auto" pt={5}>
      <Grid container spacing={1} rowSpacing={5} justifyContent="center">
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
          <MKBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} pt={2.5} pb={2.875} px={2.5} textAlign="center" lineHeight={1}>
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Success
            </MKTypography>
            <MKTypography variant="button" color="white">
            Your message has been sent! We'll get back to you soon.
            </MKTypography>
          </MKBox>
          <MKBox p={3}>
            <MKBox component="form" role="form">
              <MKBox mt={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  <MKTypography component={Link} to="/" variant="button" color="info" fontWeight="medium" textGradient>
                    Return to Home Page
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
        </Grid>
      </Grid>
    </MKBox>
  </MKBox>
);

const ContactForm = ({ formData, handleChange, handleSubmit, missingData }) => (
    <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
      <MKBox width="100%" mx="auto" pt={5}>
          <Grid container spacing={1} rowSpacing={5} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={5} xl={5}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                pt={2.5}
                pb={2.875}
                px={2.5}
                textAlign="center"
                lineHeight={1}
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Contact Us
                </MKTypography>
                { missingData && 
                  <MKBox
                  variant="gradient"
                  bgColor="error"
                  borderRadius="lg"
                  mx={2}
                  mt={3}
                  pt={2}
                  pb={2}
                  px={2.5}
                  textAlign="center"
                  lineHeight={1}
                >
                    <MKTypography variant="h6" color="white" fontWeight="medium" >
                      Please fill in all required fields
                    </MKTypography>
                    </MKBox>
                  }
              </MKBox>
              <MKBox p={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput 
                      type="text"
                      label="Enter your name"
                      name="first_name"
                      fullWidth
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                      />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput 
                      type="text"
                      label="Enter your email"
                      name="email"
                      fullWidth
                      value={formData.email}
                      onChange={handleChange}
                      required
                      />
                  </MKBox>
                  <Grid item xs={12}>
                    <MKInput
                      required
                      type="text"
                      label="What can we help you with?"
                      name="request_data"
                      multiline
                      fullWidth
                      value={formData.request_data}
                      onChange={handleChange}
                      rows={6}
                    />
                  </Grid>
                  <MKBox mt={2} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                        Submit
                    </MKButton>
                  </MKBox>
                  <MKBox mt={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      <MKTypography component={Link} to="/user/login" variant="button" color="info" fontWeight="medium" textGradient >
                        Already Have an Account? 
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
);

export default Contact;

