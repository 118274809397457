import { Box } from '@mui/material';
import { Chart, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import { useEffect, useRef } from 'react';

// Register Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  BoxPlotController,
  BoxAndWiskers,
  Title,
  Tooltip,
  Legend
);

function calculateMean(values) {
    const sum = values.reduce((acc, val) => acc + val, 0);
    return sum / values.length;
}
function calculateMedian(values) {
    values.sort((a, b) => a - b); // Sort array in ascending order
    const mid = Math.floor(values.length / 2);

    if (values.length % 2 !== 0) {
        return values[mid]; // Odd length, return middle value
    }
    return (values[mid - 1] + values[mid]) / 2; // Even length, return average of two middle values
}

const calculateBoxPlotData = (metricsData, primaryMetric, selectedMetricType) => {
  if (!primaryMetric || !metricsData.data || !metricsData.headers) return null;

  // Get the metric index based on metric type (E, M, PE, PM)
  const metricTypeIndex = {
    'E': 0,
    'M': 1,
    'PE': 2,
    'PM': 3
  }[selectedMetricType] || 0;

  const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[metricTypeIndex]);
  if (metricIndex === -1) return null;

  // Collect all valid values based on the view mode
  let values;
  if (metricsData.viewMode === 'counties') {
    // For county view, directly map the values from county data
    values = Object.values(metricsData.data)
      .map(value => Array.isArray(value) ? value[metricIndex] : null)
      .filter(value => value !== null && value !== undefined && Number(value) >= 0)
      .map(Number);
  } else {
    // For tract view, handle nested tract data structure
    values = Object.values(metricsData.data)
      .flatMap(county => 
        Object.values(county)
          .map(tract => tract[metricIndex])
          .filter(value => value !== null && value !== undefined && Number(value) >= 0)
      )
      .map(Number);
  }

  if (values.length === 0) return null;
  // Sort values in ascending order before calculations
  values.sort((a, b) => a - b);
  // Calculate statistics
  const q1 = values[Math.floor(values.length * 0.25)];
  const median = calculateMedian(values);
  const mean = calculateMean(values);
  const q3 = values[Math.floor(values.length * 0.75)];


  // Calculate outliers (values outside 1.5 * IQR)
  const iqr = q3 - q1;
  const lowerBound = q1 - 1.5 * iqr;
  const upperBound = q3 + 1.5 * iqr;

  const outliers = values.filter(v => v < lowerBound || v > upperBound);
  const nonOutliers = values.filter(v => v >= lowerBound && v <= upperBound);

  // Get whisker ends (min/max non-outlier values)
  const whiskerMin = nonOutliers[0];
  const whiskerMax = nonOutliers[nonOutliers.length - 1];

  return [{
    x: primaryMetric.label,
    min: whiskerMin,
    q1,
    median,
    q3,
    max: whiskerMax,
    outliers,
    mean
  }];
};

const MetricBoxPlot = ({ metricsData, primaryMetric, selectedMetricType }) => {
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  
  useEffect(() => {
    const data = calculateBoxPlotData(metricsData, primaryMetric, selectedMetricType);
    if (!data || !data[0] || !canvasRef.current) return;

    // Get the raw values from metricsData
    const metricTypeIndex = {
      'E': 0,
      'M': 1,
      'PE': 2,
      'PM': 3
    }[selectedMetricType] || 0;

    const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[metricTypeIndex]);
    let rawValues;
    
    if (metricsData.viewMode === 'counties') {
      rawValues = Object.values(metricsData.data)
        .map(value => Array.isArray(value) ? value[metricIndex] : null)
        .filter(value => value !== null && value !== undefined && Number(value) >= 0)
        .map(Number);
    } else {
      rawValues = Object.values(metricsData.data)
        .flatMap(county => 
          Object.values(county)
            .map(tract => Array.isArray(tract) ? tract[metricIndex] : null)
            .filter(value => value !== null && value !== undefined && Number(value) >= 0)
        )
        .map(Number);
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext('2d');

    const boxPlotData = {
      labels: [data[0].x],
      datasets: [{
        label: primaryMetric.label,
        data: [rawValues],  // Pass the raw values array as a single dataset item
        backgroundColor: 'rgba(66, 133, 244, 0.3)',
        borderColor: 'rgba(66, 133, 244, 0.8)',
        borderWidth: 2,
        outlierBackgroundColor: '#ff5722',
        outlierBorderColor: '#ff5722',
        outlierRadius: 3,
        outlierStyle: 'circle',
        medianColor: '#FFA500',
        meanStyle: 'rectRounded',
        meanRadius: 5,
        meanBackgroundColor: '#90EE90',
        meanBorderColor: '#ffffff',
        itemRadius: 0,
        lowerBackgroundColor: 'rgba(66, 133, 244, 0.2)'
      }]
    };

    const options = {
      indexAxis: 'y',
      responsive: true,
      aspectRatio: 8,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => {
              const dataset = chart.data.datasets[0];
              return [
                {
                  text: 'Outliers',
                  fillStyle: dataset.outlierBackgroundColor,
                  strokeStyle: dataset.outlierBorderColor,
                },
                {
                  text: 'Mean',
                  fillStyle: dataset.meanBackgroundColor,
                  strokeStyle: dataset.meanBackgroundColor,
                },
                {
                  text: 'Median',
                  fillStyle: dataset.medianColor,
                  strokeStyle: dataset.medianColor,
                },
              ];
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            display: true
          },
          ticks: {
            display: false
          },
          border: {
            display: false
          }
        },
        x: {
          min: Math.min(...rawValues),
          grid: {
            color: 'rgba(0, 0, 0, 1)'
          }
        }
      }
    };

    chartRef.current = new Chart(ctx, {
      type: 'boxplot',
      data: boxPlotData,
      options: options
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [
    primaryMetric,
    selectedMetricType,
    metricsData.viewMode
  ]);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '-15px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '800px',
        padding: '5px 0px 16px 0px',
        background: 'rgba(255, 255, 255, 1)',
        borderRadius: '8px',
        zIndex: 1000,
      }}
    >
      <canvas ref={canvasRef} style={{ width: '100%', height: '10px', display: 'block' }} />
    </Box>
  );
};


export default MetricBoxPlot; 
