
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import MKButton from "components/MKButton";

const DownloadPurchasedProduct = (props) => {
    const [productURL, setProductURL] = useState("");
    const [error, setError] = useState("");
    const authHeader = useAuthHeader();

    useEffect(() => {
        if (productURL) {
            downloadFile(productURL);
            setProductURL(""); 
        }
        if (error) {
            alert("Something went wrong with the download. Please try again. If you think there is an error please contact support. Thanks!");
            setError(""); 
        }
    }, [productURL, error]);

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'filename.ext'); 
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const downloadProduct = () => {
        const config = {
            headers: {
                Authorization: authHeader
            }
        };

        axios.get(`${process.env.REACT_APP_API_URL}/products/purchased-product-download/${props.id}`, config)
            .then(response => {
                setProductURL(response.data.url);
            })
            .catch(error => {
                setError("Download failed");
            });
    };

    return (
        <MKButton variant="gradient" color="info" onClick={downloadProduct}
        sx={{ justifyContent: 'center', alignItems: 'center' }}>
            Download
        </MKButton>
    );
};

export default DownloadPurchasedProduct;
