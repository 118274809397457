/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "../../../components/MKBox/index";
import MKButton from "../../../components/MKButton/index";
import MKTypography from "../../../components/MKTypography/index";
import StateSVGComponent from '../../../components/images/StateSVGs';

const StateLinks = ({ allStates, getStateUrl }) => {
  const handleStateClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container>
      <Grid container flexDirection="column" alignItems="center">
        <Box sx={{ maxWidth: "100%", my: 4 }}>
          <MKTypography variant="h2" align="center" fontWeight="bold" mb={2} color="black">
            Find Income Data for Your State
          </MKTypography>

          <nav aria-label="State Income Data">
            <MKBox mt={2} p={1} bgcolor="grey.100" borderRadius="lg">
              <Grid container spacing={2} justifyContent="center">
                {allStates.map((state, index) => (
                  <Grid item key={index}>
                    <MKButton
                      component={Link}
                      to={getStateUrl(state)}
                      variant="contained"
                      color="secondary"
                      onClick={handleStateClick}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 15px",
                        fontSize: { xs: "0.8rem", sm: "0.9rem", md: "1rem" },
                        minWidth: "max-content",
                        "& .MuiButton-startIcon": {
                          margin: 0,
                          marginBottom: "8px",
                        },
                      }}
                      title={`Explore income data for ${state} ZIP codes`}
                      aria-label={`View map of median and mean household income data for ${state} and purchase more detailed data`}
                    >
                      <StateSVGComponent stateName={state} size={24} color={"white"} />
                      <span>{state}</span>
                    </MKButton>
                  </Grid>
                ))}
              </Grid>
            </MKBox>
          </nav>
        </Box>
      </Grid>

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(getBreadcrumbSchema(allStates, getStateUrl)) }} />
    </Container>
  );
};

const getBreadcrumbSchema = (allStates, getStateUrl) => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": allStates.map((state, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "name": `${state} Income Data`,
      "item": `https://terragrasp.com${getStateUrl(state)}`,
    })),
  };
};

export default StateLinks;