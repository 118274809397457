import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StateSVGComponent from 'components/images/StateSVGs';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const StateDropDown = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.initialSet.toLowerCase());
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { showPrice = true } = props;
    
    useEffect(() => {
        if (props.initialSet) {
            setSelectedValue(props.initialSet);
        }
    }, [props.initialSet]);

    const handleDropdownChange = (event) => {
        const selectedProduct = event.target.value;
        setSelectedValue(selectedProduct);
        props.onSelectButton(selectedProduct);
    };

    // Function to format state name
    const formatStateName = (name) => {
        return name.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    // Custom arrow component
    const CustomArrow = () => (
        <Box sx={{
            position: 'absolute',
            right: 0,
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
            backgroundColor: '#f0f0f0',
            height: '100%',
            width: { xs: '40px', sm: '60px' },
            borderLeft: '2px solid rgba(0, 0, 0, 0.23)',
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',
            '&:hover': {
                backgroundColor: '#e0e0e0',
            },
            '& .MuiSvgIcon-root': {
                color: '#000000 !important',
                fontSize: { xs: '1rem', sm: '1.5rem' },
                margin: '0 auto',
                transform: 'translateX(-2px)'
            }
        }}>
            <KeyboardArrowDownIcon />
        </Box>
    );

    if (error) {
        return (
            <Alert 
                severity="error" 
                sx={{ 
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.1rem'
                }}
                action={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {loading && <CircularProgress size={20} />}
                        <span 
                            onClick={() => {
                                setLoading(true);
                                setError(null);
                                setTimeout(() => window.location.reload(), 500);
                            }}
                            style={{ 
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                marginLeft: '8px'
                            }}
                        >
                            Retry
                        </span>
                    </Box>
                }
            >
                There was an error loading the state data. Please try again.
            </Alert>
        );
    }

    if (!props.products || props.products.length === 0) {
        return (
            <Box sx={{ 
                width: '100%', 
                height: '60px', 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                border: '2px solid rgba(0, 0, 0, 0.23)'
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Select
            value={selectedValue}
            onChange={handleDropdownChange}
            IconComponent={CustomArrow}
            displayEmpty
            renderValue={(selected) => (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: { xs: '8px', sm: '16px' },
                    justifyContent: 'flex-start',
                    width: '100%',
                    backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    padding: { xs: '4px', sm: '8px' },
                    paddingRight: { xs: '50px', sm: '70px' },
                }}>
                    <StateSVGComponent 
                        stateName={selected} 
                        size={24} 
                        color={"#555555"} 
                    />
                    <span style={{ 
                        fontSize: '1.1rem',
                        color: '#000000',
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        {formatStateName(selected)}
                    </span>
                </Box>
            )}
            sx={{
                width: "100%",
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                fontFamily: 'Tahoma, Arial, sans-serif',
                fontSize: { xs: '1.1rem', sm: '1.4rem' },
                height: { xs: '50px', sm: '60px' },
                position: 'relative',
                cursor: 'pointer',
                '& .MuiSelect-select': {
                    padding: { xs: '4px 50px 4px 4px', sm: '8px 60px 8px 8px' },
                    height: { xs: '50px', sm: '60px' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderWidth: '2px',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000000',
                    borderWidth: '2px',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000000',
                    borderWidth: '2px',
                },
                ...props.sx
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        maxHeight: 400,
                        marginTop: 1,
                        backgroundColor: '#ffffff',
                        '& .MuiMenuItem-root': {
                            padding: '16px 24px',
                            color: '#000000',
                            fontSize: '1.2rem',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                }
                            }
                        }
                    }
                }
            }}
        >
            {props.products?.map((product, index) => (
                <MenuItem 
                    key={index} 
                    value={product.product_name}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <StateSVGComponent 
                        stateName={product.product_name} 
                        size={32} 
                        color={"#555555"} 
                    />
                    <span style={{ flex: 1 }}>
                        {formatStateName(product.product_name)}
                    </span>
                    {showPrice && product.price && 
                        <span style={{ color: '#606060', marginLeft: '24px', fontSize: '1.1rem' }}>
                            ${product.price / 100}
                        </span>
                    }
                </MenuItem>
            ))}
        </Select>
    );
};

export default StateDropDown;
