import React, { useState } from 'react';
import { Container, Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import SubscriptionPage from "components/Subscriptions/SubscriptionPage";
import StateMap from 'components/maps/StateMap';
import demoGif from '../../assets/gifs/demo_income_newyork_map.gif';
import staticMap from '../../assets/images/map_image_default.png';
import LandingMetricDisplay from './LandingMetricDisplay';
import { Helmet } from 'react-helmet';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SavingsIcon from '@mui/icons-material/Savings';
import CampaignIcon from '@mui/icons-material/Campaign';
import ApartmentIcon from '@mui/icons-material/Apartment';

const BuyIncomeMap = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // Color palette
    const colors = {
        primary: '#0077b6',
        secondary: '#48cae4',
        accent: '#00b4d8',
        light: '#90e0ef',
        lightest: '#caf0f8',
        dark: '#03045e'
    };

    // Set the price valid until to the next year for helmet
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    const priceValidUntil = nextYear.toISOString().split('T')[0];

    // Group metrics by their hierarchy type
    const metricsByType = {
        DP02: [
            {
                id: "DP02_0088",
                label: "Total Population",
                item_mapping: ["DP02_0088E", "DP02_0088M", "DP02_0088PE", "DP02_0088PM"]
            },
            {
                id: "DP02_0090",
                label: "Population Born In United States",
                item_mapping: ["DP02_0090E", "DP02_0090M", "DP02_0090PE", "DP02_0090PM"]
            },
            {
                id: "DP02_0092",
                label: "Population Born In Different State",
                item_mapping: ["DP02_0092E", "DP02_0092M", "DP02_0092PE", "DP02_0092PM"]
            }
        ],
        DP03: [
            {
                id: "DP03_0068",
                label: "Total Households With Retirement Income",
                item_mapping: ["DP03_0068E", "DP03_0068M", "DP03_0068PE", "DP03_0068PM"]
            },
            {
                id: "DP03_0002",
                label: "Population 16 Years And Over Employment rate",
                item_mapping: ["DP03_0002E", "DP03_0002M", "DP03_0002PE", "DP03_0002PM"]
            },

        ],
        DP04: [
            {
                id: "DP04_0089",
                label: "Median (dollars) Value Of Owner-occupied Housing Units",
                item_mapping: ["DP04_0089E", "DP04_0089M", "DP04_0089PE", "DP04_0089PM"]
            },
            {
                id: "DP04_0001",
                label: "Total housing units",
                item_mapping: ["DP04_0001E", "DP04_0001M", "DP04_0001PE", "DP04_0001PM"]
            }
        ],
        DP05: [
            {
                id: "DP05_0089",
                label: "Citizen, 18 And Over Population",
                item_mapping: ["DP05_0089E", "DP05_0089M", "DP05_0089PE", "DP05_0089PM"]
            },
            {
                id: "DP05_0001",
                label: "Total population",
                item_mapping: ["DP05_0001E", "DP05_0001M", "DP05_0001PE", "DP05_0001PM"]
            }
        ]
    };

    const [currentProfile, setCurrentProfile] = useState('DP03');

    const renderHeroSection = () => (
        <MKBox
            minHeight="75vh"
            width="100%"
            sx={{
                backgroundImage: 'linear-gradient(to right, rgba(0,119,182,0.9), rgba(0,180,216,0.9))',
                display: "flex",
                alignItems: "center",
                position: 'relative',
                mt: '-90px',
                pt: '90px'
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <MKBox p={{ xs: 2, lg: 6 }}>
                            <MKTypography
                                variant="h1"
                                color="white"
                                mb={3}
                                sx={{
                                    fontSize: {
                                        xs: '2rem',
                                        md: '3.5rem'
                                    },
                                    fontWeight: 700,
                                    wordBreak: 'break-word'
                                }}
                            >
                                Interactive Income Maps For
                                <br />
                                Comprehensive Data Visualization
                            </MKTypography>
                            <MKTypography
                                variant="body1"
                                color="white"
                                opacity={0.8}
                                mb={6}
                                pr={{ xs: 0, md: 8 }}
                                sx={{
                                    fontSize: {
                                        xs: '0.9rem',
                                        md: '1.25rem'
                                    }
                                }}
                            >
                                Explore 500+ metrics across <strong>Economic</strong>, <strong>Demographic</strong>, <strong>Social</strong>, and <strong>Housing</strong> data. 
                                Make informed decisions with our interactive maps and detailed analytics at the tract level.
                            </MKTypography>
                            <MKButton
                                variant="contained"
                                color="warning"
                                size="large"
                                onClick={() => {
                                    document.getElementById('pricing').scrollIntoView({ 
                                        behavior: 'smooth',
                                        block: 'start'
                                    });
                                }}
                                sx={{
                                    px: 6,
                                    py: 2,
                                    fontSize: '1.1rem'
                                }}
                            >
                                Start Mapping Now
                            </MKButton>
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox
                            sx={{
                                position: 'relative',
                                width: '100%',
                                paddingTop: { xs: '50%', md: '62.6%' },
                                overflow: 'hidden',
                                borderRadius: '8px',
                                boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
                                mx: 'auto',
                                maxWidth: { xs: '100%', md: '600px' },
                                '& picture': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%'
                                },
                                '& img': {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    backgroundColor: colors.lightest
                                }
                            }}
                        >
                            <picture>
                                <source 
                                    srcSet={demoGif} 
                                    type="image/gif" 
                                />
                                <img 
                                    src={staticMap}
                                    alt="Interactive Income Map Preview"
                                />
                            </picture>
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderDemoSection = () => (
        <MKBox
            component="section"
            sx={{ 
                background: 'white',
                position: 'relative',
                overflow: 'visible',
                py: { xs: 4, sm: 6, md: 8 }
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="center" mb={4}>
                    <Grid item xs={12} textAlign="center">
                        <MKTypography 
                            variant="h2" 
                            mb={2}
                            sx={{
                                fontSize: {
                                    xs: '1.75rem',
                                    sm: '2rem',
                                    md: '2.5rem'
                                }
                            }}
                        >
                            Explore Our Data Platform
                        </MKTypography>
                        <MKTypography 
                            variant="body1" 
                            color="text" 
                            mb={4}
                            sx={{
                                px: { xs: 2, sm: 4, md: 0 },
                                fontSize: {
                                    xs: '0.9rem',
                                    sm: '1rem',
                                    md: '1.1rem'
                                }
                            }}
                        >
                            Explore 500+ metrics including average retirement income, social security income, 
                            mean and median household income across all 50 US states. Our powerful mapping 
                            tools help visualize comprehensive economic, social, demographic, and housing data at the census tract level.
                        </MKTypography>
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        position: 'relative',
                        height: { xs: '50vh', sm: '60vh', md: '70vh' },
                        minHeight: { xs: '400px', sm: '500px' },
                        maxHeight: { xs: '500px', sm: '600px', md: '700px' },
                        borderRadius: '16px',
                        overflow: 'hidden',
                        boxShadow: '20px 20px 40px rgba(0,0,0,0.1)',
                        mb: 2,
                    }}
                >
                    <StateMap 
                        initialState="Arizona"
                        initialMetrics={metricsByType[currentProfile]}
                        hierarchyType={currentProfile}
                        demo={true}
                        panel={!isMobile}
                        metricsByType={metricsByType}
                        onProfileChange={(newProfile) => {
                            setCurrentProfile(newProfile);
                        }}
                    />
                </Box>
            </Container>
        </MKBox>
    );

    const renderApplicationSection = () => (
        <MKBox
        component="section"
        sx={{ 
            background: 'white',
            position: 'relative',
            overflow: 'visible',
            py: { xs: 0.5, md: 1}
        }}
    >
        <Container maxWidth="lg" alignItems="center">
        <Box sx={{ pt: { xs: 0.5, md: 1} }}>
        <MKTypography 
            variant="h2" 
            mb={{ xs: 1, md: 2 }}
            align="center"
            sx={{
                fontSize: {
                    xs: '1.5rem',
                    sm: '2rem', 
                    md: '2.5rem'
                }
            }}
        >
            Industry Applications
        </MKTypography>
        <Grid container spacing={{ xs: 1.5, md: 3 }}>
            {[
                {
                    title: "Door-to-Door Sales",
                    icon: DoorFrontIcon,
                    description: "Optimize your sales territory by identifying high-potential neighborhoods for services like solar installation, internet packages, or home services. Target areas with specific home characteristics, income levels, and homeownership patterns to maximize your conversion rates and minimize travel time.",
                    metrics: [
                        "Home ownership status",
                        "Household Income",
                        "Property age & type",
                        "Population density metrics"
                    ]
                },
                {
                    title: "Insurance Market Analysis",
                    icon: HealthAndSafetyIcon,
                    description: "Identify areas with varying health insurance coverage rates and income levels. Analyze population demographics by age groups and employment status to understand market potential and risk factors. Perfect for insurance companies looking to expand their market presence or adjust coverage offerings.",
                    metrics: [
                        "Health insurance coverage rates",
                        "Age-specific population data",
                        "Employment statistics",
                        "Income level distribution"
                    ]
                },
                {
                    title: "Retirement Services",
                    icon: SavingsIcon,
                    description: "Target areas with high concentrations of retirement-age populations and analyze their income. Access detailed data about households with retirement income, social security benefits, and age distribution to optimize retirement service offerings and locations.",
                    metrics: [
                        "Mean Retirement Income",
                        "Mean Social security Earnings",
                        "65+ age population data",
                        "65+ Earnings Data"
                    ]
                },
                {
                    title: "Demographic Marketing",
                    icon: CampaignIcon,
                    description: "Perfect for marketing agencies and businesses looking to understand their target audience. Analyze household types, age distributions, and cultural backgrounds. Identify areas with specific demographic profiles, including education levels, family structures, and income brackets for targeted campaign planning.",
                    metrics: [
                        "Household composition",
                        "Cultural demographics",
                        "Education levels",
                        "Income brackets"
                    ]
                },
                {
                    title: "Real Estate Development",
                    icon: ApartmentIcon,
                    description: "Make informed property development decisions by analyzing housing values, vacancy rates, and rental markets. Understand neighborhood demographics, income levels, and housing age distribution to identify prime locations for new developments or renovation opportunities.",
                    metrics: [
                        "Property values",
                        "Occupancy rates",
                        "Housing type distribution",
                        "Neighborhood demographics"
                    ]
                }
            ].map((feature, index) => (
                <Grid item xs={12} key={index}>
                    <Box sx={{ 
                        p: { xs: 2, md: 4 },
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        border: '1px solid rgba(0,0,0,0.1)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            boxShadow: '0 8px 24px rgba(0,0,0,0.1)',
                            borderColor: 'transparent'
                        }
                    }}>
                        <Grid container spacing={{ xs: 1, md: 3 }}>
                            <Grid item xs={12} md={8}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, md: 2 }, mb: { xs: 1, md: 2 } }}>
                                    <feature.icon 
                                        fontSize="large"
                                        sx={{ 
                                            width: { xs: '1.5rem', md: '3.5rem' },
                                            height: { xs: '1.5rem', md: '3.5rem' },
                                            color: 'rgb(0, 0, 102)'
                                        }} 
                                    />
                                    <MKTypography 
                                        variant="h5" 
                                        sx={{
                                            fontSize: { xs: '1.2rem', md: '1.75rem' },
                                            color: 'rgb(0, 0, 102)',
                                            fontWeight: 600
                                        }}
                                    >
                                        {feature.title}
                                    </MKTypography>
                                </Box>
                                <MKTypography 
                                    variant="body1" 
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: { xs: '0.75rem', md: '1.1rem' },
                                        lineHeight: { xs: 1.4, md: 1.6 },
                                        mb: { xs: 1, md: 0 },
                                        pl: { xs: 1, md: 3 }
                                    }}
                                >
                                    {feature.description}
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box>
                                    <MKTypography 
                                        variant="subtitle1" 
                                        mb={{ xs: 0.5, md: 1 }}
                                        sx={{
                                            color: 'rgb(0, 0, 102)',
                                            fontWeight: 600,
                                            fontSize: { xs: '0.9rem', md: '1.1rem' }
                                        }}
                                    >
                                        Identify Areas with High:
                                    </MKTypography>
                                    <Box 
                                        component="ul" 
                                        sx={{ 
                                            listStyle: 'none',
                                            p: 0,
                                            m: 0,
                                            '& li': {
                                                position: 'relative',
                                                pl: 3,
                                                mb: { xs: 0.5, md: 1 },
                                                '&:last-child': { mb: 0 },
                                                '&:before': {
                                                    content: '"•"',
                                                    position: 'absolute',
                                                    left: 0,
                                                    color: 'rgb(0, 119, 182)',
                                                    fontSize: '1.2rem',
                                                    lineHeight: 1.2
                                                }
                                            }
                                        }}
                                    >
                                        {feature.metrics.map((metric, idx) => (
                                            <li key={idx}>
                                                <MKTypography 
                                                    variant="body2"
                                                    sx={{
                                                        color: 'text.primary',
                                                        fontSize: { xs: '0.8rem', md: '1rem' },
                                                        fontWeight: 500,
                                                        lineHeight: { xs: 1.3, md: 1.5 }
                                                    }}
                                                >
                                                    {metric}
                                                </MKTypography>
                                            </li>
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ))}
        </Grid>
        </Box>
        </Container>
        </MKBox>
    );



    return (
        <>
            <Helmet>
                <title>Interactive Income/Demographic Maps & Data Visualization</title>
                <meta name="description" content="Explore interactive income maps with 500+ metrics including demographic, economic, and housing data. Visualize data at the tract level across all US states." />
                <link rel="canonical" href={`${window.location.origin}/income-map`} />
                <meta property="og:title" content="Interactive Income/Demographic Maps & Data Visualization" />
                <meta property="og:description" content="Explore interactive income maps with 500+ metrics including demographics, economic insights, and housing data." />
                <meta property="og:url" content={`${window.location.origin}/income-map`} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": "TerraGrasp State-Specific Data Mapping",
                        "description": "Access to comprehensive income and demographic data for individual U.S. states.",
                        "offers": [
                            {
                                "@type": "Offer",
                                "priceCurrency": "USD",
                                "price": "19.99",
                                "priceValidUntil": priceValidUntil,
                                "itemCondition": "https://schema.org/NewCondition",
                                "availability": "https://schema.org/InStock",
                                "seller": {
                                    "@type": "Organization",
                                    "name": "TerraGrasp"
                                },
                                "description": "Monthly subscription for state-specific data map access."
                            },
                            {
                                "@type": "Offer",
                                "priceCurrency": "USD",
                                "price": "179.88",
                                "priceValidUntil": priceValidUntil,
                                "itemCondition": "https://schema.org/NewCondition",
                                "availability": "https://schema.org/InStock",
                                "seller": {
                                    "@type": "Organization",
                                    "name": "TerraGrasp"
                                },
                                "description": "Annual subscription for state-specific data map access at a discounted rate of $14.99 per month."
                            }
                        ]
                    })}
                </script>
            </Helmet>
            {/* Hero Section */}
            {renderHeroSection()}

            {/* Demo Section */}
            {renderDemoSection()}

            {/* Subscription Section */}
            <MKBox id="pricing">
                <SubscriptionPage />
            </MKBox>
            {renderApplicationSection()}
            {/* Metrics Display Section */}
            <MKBox 
                sx={{ 
                    background: 'white',
                    borderTop: '1px solid rgba(0,0,0,0.1)'
                }}
            >
                <LandingMetricDisplay colors={colors} />
            </MKBox>

            {/* Application Section */}
            
        </>
    );
};
export default BuyIncomeMap; 