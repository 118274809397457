/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { Helmet } from 'react-helmet-async';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

import Newsletter from "./sections/Newsletter";
import StateImage from "./images/StateImages";
import MapsToProducts from "./sections/MapsToProducts";
import demoGif from 'assets/gifs/demo_income_newyork_map.gif';
import { RetrieveImage } from "./images/GetImages";

function CustomLanding() {
  return (
    <>
      <Helmet>
        <title>Income Data & Maps by ZIP Code | Download Reports</title>
        <meta name="description" content="Access detailed income data insights for all U.S. ZIP codes. Compare regions on a map for income, retirement, housing, and demographic data. Explore 500+ metrics. Download reports instantly!" />
        <link rel="canonical" href={window.location.origin} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "TerraGrasp",
            "description": "Comprehensive income and demographic data reports for U.S. states by ZIP code",
            "url": window.location.origin,
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": `${window.location.origin}/income-by-zipcode/{state_name}`
              },
              "query-input": "required name=state_name"
            }
          })}
        </script>
      </Helmet>
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: 'linear-gradient(to right, rgba(0,119,182,0.9), rgba(0,180,216,0.9))',
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "flex-start", lg: "center" },
          alignItems: "center",
          mt: '-90px',
          pt: { xs: '180px', lg: '90px' }
        }}
      >
        <Container>
          <Grid container spacing={{ xs: 2, lg: 6 }} alignItems="center">
            {/* Map Section */}
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '24px',
                  overflow: 'hidden',
                  p: 0,
                  width: '100%',
                  height: 0,
                  paddingTop: { xs: '60%', md: '75%' },
                  mt: { xs: 3, lg: 0 }
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img 
                    src={RetrieveImage("usa_map")}
                    alt="USA income by zipcode map"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Content Section */}
            <Grid item xs={12} md={5}>
              <Box sx={{ 
                textAlign: { xs: 'center', md: 'left' }, 
                mt: { xs: 4, md: 0 },
                px: { xs: 2, md: 0 }
              }}>
                <MKTypography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '2.5rem', md: '3.5rem' },
                    fontWeight: 700,
                    color: '#ffffff',
                    mb: { xs: 2, md: 3 },
                    lineHeight: 1.2,
                  }}
                >
                  Income By Zip Code Data Reports
                </MKTypography>
                <MKTypography 
                  variant="h4"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: { xs: 3, md: 4 },
                    lineHeight: 1.5,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Zip code Data Report and Maps for all 50 states
                </MKTypography>

                {/* Buttons Stack */}
                <Grid container spacing={2} sx={{ mt: { xs: 2, md: 4 } }}>
                  {/* Reports Button */}
                  <Grid item xs={12}>
                    <MKButton
                      variant="contained"
                      color="success"
                      size="large"
                      component={Link}
                      to="/income-by-zipcode/usa"
                      fullWidth
                      sx={{
                        py: { xs: 1.5, md: 2 },
                        fontSize: { xs: '1.1rem', md: '1.1rem' },
                        borderRadius: '8px',
                        backgroundColor: '#2e7d32',
                        '&:hover': {
                          backgroundColor: '#1b5e20'
                        }
                      }}
                    >
                      GET INCOME BY ZIPCODE REPORTS
                    </MKButton>
                  </Grid>
                  
                  {/* Maps Button */}
                  <Grid item xs={12}>
                    <MKButton
                      variant="contained"
                      color="warning"
                      size="large"
                      component={Link}
                      to="/income-map"
                      fullWidth
                      sx={{
                        py: { xs: 1.5, md: 2 },
                        fontSize: { xs: '1.1rem', md: '1.1rem' },
                        borderRadius: '8px',
                        backgroundColor: '#ff9800',
                        '&:hover': {
                          backgroundColor: '#f57c00'
                        }
                      }}
                    >
                      Interactive Income Maps
                    </MKButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: { xs: 2, md: 4 },
          mx: 'auto',
          mt: { xs: 4, md: -8 },
          mb: 4,
          maxWidth: '1200px',
          borderRadius: { xs: 0, md: '16px' },
          boxShadow: { 
            xs: 'none', 
            md: '0 20px 40px rgba(0,0,0,0.1)' 
          },
          backgroundColor: { xs: '#ffffff', md: '#ffffff' },
        }}
      >
        {/* Product Options Side by Side */}
        <Grid container spacing={4}>
          {/* Interactive Maps Option */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#f8f9fa',
                borderRadius: { xs: 0, md: '16px' },
                p: { xs: 0, md: 3 },
                mb: { xs: 4, md: 0 },
                height: '100%',
                transition: 'transform 0.3s',
                boxShadow: { 
                  xs: 'none',
                  md: '0 4px 12px rgba(0,0,0,0.1)'
                },
                '&:hover': {
                  transform: { xs: 'none', md: 'translateY(-5px)' },
                  boxShadow: { 
                    xs: 'none',
                    md: '0 6px 16px rgba(0,0,0,0.15)'
                  },
                }
              }}
            >
              <MKTypography variant="h4" color="dark" mb={2} sx={{ 
                textAlign: 'center',
                fontSize: { xs: '1.5rem', md: '2rem' }
              }}>
                Interactive Income Maps
              </MKTypography>

              {/* Map GIF */}
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  mb: 3,
                  height: 0,
                  paddingTop: '60%',
                }}
              >
                <picture>
                  <source srcSet={demoGif} type="image/gif" />
                  <img 
                    src={StateImage("usa")}
                    alt="Interactive Income Data Map Demo"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '8px'
                    }}
                  />
                </picture>
              </Box>

              <ul style={{ 
                listStyle: 'none', 
                padding: 0,
                margin: 0 
              }}>
                {[
                  'View Economic, Housing, Demographic, and Social Data',
                  'Visual data exploration at census tract level',
                  'Compare multiple metrics simultaneously',
                  '500+ available metrics'
                ].map((text, index) => (
                  <li key={index} style={{ 
                    marginBottom: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#344767',
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}>
                    <span style={{ color: '#ff9800', marginRight: '0.75rem' }}>✓</span>
                    {text}
                  </li>
                ))}
              </ul>
              <Box sx={{ mt: 'auto', pt: 3 }}>
                <MKButton 
                  variant="contained"
                  color="warning"
                  size="large"
                  component={Link} 
                  to='/income-map'
                  fullWidth
                  sx={{ 
                    py: { xs: 1.5, md: 2 },
                    fontSize: { xs: '1rem', md: '1.1rem' },
                    borderRadius: '8px',
                  }}
                >
                  Explore Maps
                </MKButton>
              </Box>
            </Box>
          </Grid>

          {/* Income Reports Option */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#f8f9fa',
                borderRadius: '16px',
                p: 3,
                height: '100%',
                transition: 'transform 0.3s',
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
                }
              }}
            >
              <MKTypography variant="h4" color="dark" mb={2} sx={{ textAlign: 'center' }}>
                Income By Zipcode Data Reports
              </MKTypography>
              {/* USA Image */}
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  mb: 3,
                  height: 0,
                  paddingTop: '60%',
                }}
              >

                <img 
                  src={StateImage("usa")}
                  alt="USA Income Data Coverage"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    padding: '16px',
                    borderRadius: '8px'
                  }}
                />
              </Box>

              <ul style={{ 
                listStyle: 'none', 
                padding: 0,
                margin: 0 
              }}>
                {[
                  'Comprehensive zip code level data',
                  'Income brackets & distributions',
                  'Retirement & social security income',
                  'Excel format with 98 metrics'
                ].map((text, index) => (
                  <li key={index} style={{ 
                    marginBottom: '0.25rem',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#344767'
                  }}>
                    <span style={{ color: '#ff9800', marginRight: '0.75rem' }}>✓</span>
                    {text}
                  </li>
                ))}
              </ul>
              <Box sx={{ mt: 'auto', pt: 3 }}>
              <MKButton 
                  variant="contained"
                  color="success"
                  size="large"
                  component={Link} 
                  to='/income-by-zipcode/usa'
                  fullWidth
                  sx={{ 
                    py: 1.5,
                    fontSize: '1.1rem',
                    borderRadius: '8px',
                  }}
                >
                  Get Income Reports
                </MKButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <MapsToProducts />
        
        <Newsletter 
          text="Empower your decisions with data! Subscribe now to get exclusive access to cutting-edge census insights and analytics. Don't miss out on the knowledge that shapes the future."
        />
      </Card>
    </>
  );
}

export default CustomLanding;