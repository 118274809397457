import React from 'react';
import Icon from "@mui/material/Icon";
import StorageIcon from '@mui/icons-material/Storage';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import MapIcon from '@mui/icons-material/Map';
const routes = [
  {
    name: "Get Data",
    icon: <StorageIcon/>,
    route: "/income-by-zipcode/usa",
  },
  {
    name: "Map Data",
    icon: <MapIcon/>,
    route: "/income-map",
  },
  {

    name: "State Data",
    icon: <AutoGraphIcon/>,

    columns: 5,
    rowsPerColumn: 1,
    collapse: [
      {
        collapse: [
          {
          route: 'state-income-data/alabama',
          name: "Alabama",
          },
          {
            name: "Alaska",
            route: 'state-income-data/alaska',
          },
          {
            name: "Arizona",
            route: 'state-income-data/arizona',
          },
          {
            name: "Arkansas",
            route: 'state-income-data/arkansas',
          },
          {
            name: "California",
            route: 'state-income-data/california',
          },
            {name: "Colorado",
            route: 'state-income-data/colorado',
            },
            {name: "Connecticut",
            route: 'state-income-data/connecticut',
            },
            {name: "Delaware",
            route: 'state-income-data/delaware',
            },
            {name: "Florida",
            route: 'state-income-data/florida',
            },
            {name: "Georgia",
            route: 'state-income-data/georgia',
            },
        ],
      },
      {
      route: 'state-income-data/alaska',
      collapse: [
        {name: "Hawaii",
          route: 'state-income-data/hawaii',
          },
          {name: "Idaho",
          route: 'state-income-data/idaho',
          },
          {name: "Illinois",
          route: 'state-income-data/illinois',
          },
          {name: "Indiana",
          route: 'state-income-data/indiana',
          },
          {name: "Iowa",
          route: 'state-income-data/iowa',
          },
          {name: "Kansas",
          route: 'state-income-data/kansas',
          },
          {name: "Kentucky",
          route: 'state-income-data/kentucky',
          },
          {name: "Louisiana",
          route: 'state-income-data/louisiana',
          },
          {name: "Maine",
          route: 'state-income-data/maine',
          },
          {name: "Maryland",
          route: 'state-income-data/maryland',
          },
      ]
      },
      {
        collapse: [
          {name: "Massachusetts",
            route: 'state-income-data/massachusetts',
            },
            {name: "Michigan",
            route: 'state-income-data/michigan',
            },
            {name: "Minnesota",
            route: 'state-income-data/minnesota',
            },
            {name: "Mississippi",
            route: 'state-income-data/mississippi',
            },
            {name: "Missouri",
            route: 'state-income-data/missouri',
            },
            {name: "Montana",
            route: 'state-income-data/montana',
            },
            {name: "Nebraska",
            route: 'state-income-data/nebraska',
            },
            {name: "Nevada",
            route: 'state-income-data/nevada',
            },
            {name: "New Hampshire",
            route: 'state-income-data/new_hampshire',
            },
            {name: "New Jersey",
            route: 'state-income-data/new_jersey',
            },
        ]
      },
      {
        collapse: [
          {name: "New Mexico",
            route: 'state-income-data/new_mexico',
            },
            {name: "New York",
            route: 'state-income-data/new_york',
            },
            {name: "North Carolina",
            route: 'state-income-data/north_carolina',
            },
            {name: "North Dakota",
            route: 'state-income-data/north_dakota',
            },
            {name: "Ohio",
            route: 'state-income-data/ohio',
            },
            {name: "Oklahoma",
            route: 'state-income-data/oklahoma',
            },
            {name: "Oregon",
            route: 'state-income-data/oregon',
            },
            {name: "Pennsylvania",
            route: 'state-income-data/pennsylvania',
            },
            {name: "Rhode Island",
            route: 'state-income-data/rhode_island',
            },
            {name: "South Carolina",
            route: 'state-income-data/south_carolina',
            },
      ]
      },
      {
        collapse: [
          {name: "South Dakota",
            route: 'state-income-data/south_dakota',
            },
            {name: "Tennessee",
            route: 'state-income-data/tennessee',
            },
            {name: "Texas",
            route: 'state-income-data/texas',
            },
            {name: "Utah",
            route: 'state-income-data/utah',
            },
            {name: "Vermont",
            route: 'state-income-data/vermont',
            },
            {name: "Virginia",
            route: 'state-income-data/virginia',
            },
            {name: "Washington",
            route: 'state-income-data/washington',
            },
            {name: "West Virginia",
            route: 'state-income-data/west_virginia',
            },
            {name: "Wisconsin",
          route: 'state-income-data/wisconsin',
            },
            {name: "Wyoming",
          route: 'state-income-data/wyoming',
            },
      ]
      },
    ],
  },
  {
    name: "contact us",
    icon: <Icon>send</Icon>,
    route: "/contact-us",
  },
  {
    name: "account",
    icon: <Icon>person</Icon>,
    route: "/user/login",
    collapse: [
      {
        name: "sign in",
        route: "/user/login",
      },
      {
        name: "sign up",
        route: '/user/sign-up',
      },
    ],
  },
];

export default routes;

const signed_in_routes = routes.slice(0, -1);

signed_in_routes.push({
  name: "account",
  icon: <Icon>person</Icon>,
  route: '/account',
  collapse: [
    { 
      name: "logout",
      route: '/user/logout',
    },
    { 
      name: "my account",
      route: '/account',
    },
  ],
});


const admin_routes = [
  {
    name: "admin",
    icon: <Icon>admin</Icon>,
    route: 'admin/panel',
    collapse: [
      {
        name: "Maps",
        route: "admin/map-app/nevada",
      },
      {
        name: 'Admin panel',
        route: 'admin/panel'
      },
      {
        name: 'create-product',
        route: 'admin/create-product'
      },
      {
        name: 'stripe_products',
        route: 'admin/stripe_products'
      },
      {
        name: 'stripe_prices',
        route: 'admin/stripe_prices'
      },
      {
        name: 'products',
        route: 'admin/products'
      },
      {
        name: 'detailed-product/:id',
        route: 'admin/detailed-product/:id'
      },
      {
        name: 'create-subscription',
        route: 'admin/create-subscription'
      },
    ],
  },
];
export {admin_routes, signed_in_routes};
