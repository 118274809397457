import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import Box from '@mui/material/Box';
import MKTypography from "components/MKTypography";
import { useCart } from "react-use-cart";
import StateImage from 'components/images/StateImages';  
import IncomeSchema from 'components/product/incomeByZipComponents/IncomeSchema';
import StateDropDown from 'components/basics/StateDropDown';
import { useNavigate, useParams } from 'react-router-dom';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/Numbers';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import SpecificStateData from './incomeByZipComponents/SpecificStateData';
import SampleDataSection from './incomeByZipComponents/SampleDataSection';
import StateLinks from './incomeByZipComponents/StateLinks';
import MapIcon from '@mui/icons-material/Map';
import SpecificStateDataBottom from './incomeByZipComponents/SpecificStateDataBottom';


const ProductDetail = () => {
    const greenColors = {
        lightest: '#C7F9CC',
        light: '#80ED99',
        medium: '#57CC99',
        darkMedium: '#38A3A5',
        darkest: '#22577A'
    };
    const colors = {
        lightest: '#caf0f8',
        light: '#90e0ef',
        medium: '#00b4d8',
        darkMedium: '#0077b6',
        darkest: '#03045e'
    };
    
    const defaultState = {
        "description": "USA income by zip code",
        "id": 92,
        "image_path": "https://terragrasp-images.s3.us-west-2.amazonaws.com/fun_states/USA.webp",
        "price": null,
        "product_name": "USA",
        "product_type": "income_by_zipcode",
        "product_year": 2023,
        "quantity": 1,
        "itemTotal": 19999}
    const [productList, setProductList] = useState([]);
    const [productDetails, setProductDetails] = useState(defaultState);
    const [images, setImages] = useState(false);
    const { addItem, getItem } = useCart();
    const {
        removeItem,
        items
    } = useCart();
    const navigate = useNavigate();
    const { stateName } = useParams();
    const [stateData, setStateData] = useState(null);
    const allStates = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
        "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
        "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "Usa"
      ];
      const getStateUrl = (stateName) => {
        return `/income-by-zipcode/${stateName.replace(/\s+/g, '_')}`;
      };

    // Add this function near the top for immediate state name formatting
    const formatStateNameFromUrl = (urlStateName) => {
        if (!urlStateName) return 'USA';
        if (urlStateName.toLowerCase() === 'usa') return 'USA';
        
        // Convert state_name or state-name to proper format
        return urlStateName
            .split(/[-_]/)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    // Get formatted state name immediately from URL
    const formattedStateName = formatStateNameFromUrl(stateName);
    
    // Get base price without waiting for API
    const getBasePrice = (stateName) => {
        return stateName.toLowerCase() === 'usa' ? '199' : '49.99';
    };


    const fetchData = useCallback(async (endpoint) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/utils/state-income-data/${endpoint}`);
            return response.data;
        } catch (error) {
            // Track API errors
            ReactGA.event({
                category: 'Error',
                action: 'API Error',
                label: `State Data Fetch - ${endpoint}`,
                value: error.response?.status || 0,
            });
            return null;
        }
    }, []);

    useEffect(() => {
        const fetchStateData = async () => {
            const state = await fetchData(stateName.toLowerCase());
            setStateData(state);
        };
        if (stateName) {
            fetchStateData();
        }
    }, [stateName, fetchData]);

    useEffect(() => {
        if (productList.length < 1) {
            const fetchProducts = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/products-type/income_by_zipcode`);
                    if (Array.isArray(response.data.data)) {
                        const sortedData = response.data.data.sort((a, b) => {
                            return a.product_name.localeCompare(b.product_name);
                        });
                        const usaProduct = sortedData.filter(item => item.product_name.toLowerCase() === 'usa');
                        const otherProducts = sortedData.filter(item => item.product_name.toLowerCase() !== 'usa');
                        const finalSortedData = [...usaProduct, ...otherProducts];
                        setProductList(finalSortedData);
                    }
                    setImages(prev => true);
                } catch (error) {
                    console.error('Error fetching products:', error);
                    // If it's a connection error, retry after 2 seconds
                    if (error.message.includes('network') || error.response?.status === 500) {
                        setTimeout(() => {
                            setImages(prev => !prev); // Toggle to trigger useEffect
                        }, 2000);
                    }
                }
            };
            fetchProducts();
        }
        if (images) {
            setImages(false);
        }
        if (stateName) {
            const product = productList.find(p => p.product_name.toLowerCase() === stateName.toLowerCase());
            if (product) {
                setProductDetails(product);
            }
        }
    }, [images, productList, stateName]);
    

    const handlePurchaseUSA =() => {
        const usaProduct = productList.find(p => p.product_name.toLowerCase() === 'usa')
        const usaID = usaProduct.id
        items.forEach((item) => {
            if (item.id !== usaID) {
                removeItem(item.id);
            }
        });
        addItem(usaProduct);

        ReactGA.event({
            category: 'Ecommerce',
            action: 'Buy All US Data',
            label: 'USA',
        });

        navigate('/user/cart');
    };

    const handleProductChange =(selectValue) => {
        const product = productList.find(p => p.product_name === selectValue);
        setProductDetails(product);
        
        ReactGA.event({
            category: 'Product',
            action: 'State Selection',
            label: selectValue,
        });
        
        navigate(`/income-by-zipcode/${selectValue.replace(/ /g, '_').toLowerCase()}`, { replace: true });
    };
    
    const DownloadSample = () => {
        // Add GA event for sample download
        ReactGA.event({
            category: 'Downloads',
            action: 'Sample Data Download',
            label: 'Income Sample Data',
        });
        
        const fileUrl = 'https://terragrasp-images.s3.us-west-2.amazonaws.com/sample_products/TerraGrasp_Income_by_zip_Sample_Data.xlsx';
        window.location.href = fileUrl;
    };
    const DownloadZipCodeList = (stateName) => {
        // Add GA event for zip code list download
        ReactGA.event({
            category: 'Downloads',
            action: 'Zip Code List Download',
            label: stateName,
        });
        
        const zipCodeList = {
            "alabama": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Alabama_Zip_Codes.zip",
            "alaska": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Alaska_Zip_Codes.zip",
            "arizona": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Arizona_Zip_Codes.zip",
            "arkansas": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Arkansas_Zip_Codes.zip",
            "california": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/California_Zip_Codes.zip",
            "colorado": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Colorado_Zip_Codes.zip",
            "connecticut": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Connecticut_Zip_Codes.zip",
            "delaware": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Delaware_Zip_Codes.zip",
            "florida": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Florida_Zip_Codes.zip",
            "georgia": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Georgia_Zip_Codes.zip",
            "hawaii": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Hawaii_Zip_Codes.zip",
            "idaho": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Idaho_Zip_Codes.zip",
            "illinois": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Illinois_Zip_Codes.zip",
            "indiana": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Indiana_Zip_Codes.zip",
            "iowa": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Iowa_Zip_Codes.zip",
            "kansas": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Kansas_Zip_Codes.zip",
            "kentucky": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Kentucky_Zip_Codes.zip",
            "louisiana": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Louisiana_Zip_Codes.zip",
            "maine": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Maine_Zip_Codes.zip",
            "maryland": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Maryland_Zip_Codes.zip",
            "massachusetts": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Massachusetts_Zip_Codes.zip",
            "michigan": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Michigan_Zip_Codes.zip",
            "minnesota": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Minnesota_Zip_Codes.zip",
            "mississippi": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Mississippi_Zip_Codes.zip",
            "missouri": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Missouri_Zip_Codes.zip",
            "montana": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Montana_Zip_Codes.zip",
            "nebraska": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Nebraska_Zip_Codes.zip",
            "nevada": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Nevada_Zip_Codes.zip",
            "new_hampshire": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/New_Hampshire_Zip_Codes.zip",
            "new_jersey": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/New_Jersey_Zip_Codes.zip",
            "new_mexico": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/New_Mexico_Zip_Codes.zip",
            "new_york": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/New_York_Zip_Codes.zip",
            "north_carolina": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/North_Carolina_Zip_Codes.zip",
            "north_dakota": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/North_Dakota_Zip_Codes.zip",
            "ohio": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Ohio_Zip_Codes.zip",
            "oklahoma": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Oklahoma_Zip_Codes.zip",
            "oregon": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Oregon_Zip_Codes.zip",
            "pennsylvania": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Pennsylvania_Zip_Codes.zip",
            "rhode_island": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Rhode_Island_Zip_Codes.zip",
            "south_carolina": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/South_Carolina_Zip_Codes.zip",
            "south_dakota": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/South_Dakota_Zip_Codes.zip",
            "tennessee": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Tennessee_Zip_Codes.zip",
            "texas": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Texas_Zip_Codes.zip",
            "usa": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/USA_zip_codes.zip",
            "utah": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Utah_Zip_Codes.zip",
            "vermont": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Vermont_Zip_Codes.zip",
            "virginia": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Virginia_Zip_Codes.zip",
            "washington": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Washington_Zip_Codes.zip",
            "west_virginia": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/West_Virginia_Zip_Codes.zip",
            "wisconsin": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Wisconsin_Zip_Codes.zip",
            "wyoming": "https://terragrasp-freedownloads.s3.us-east-2.amazonaws.com/state_zip_codes/Wyoming_Zip_Codes.zip"
            }
        const fileUrl = zipCodeList[stateName.toLowerCase()];
        if (fileUrl) {
            window.location.href = fileUrl;
        } else {
            alert(`Sorry, we don't have data for the state: ${stateName}`);
        }
    };
    // Add this function to generate structured data
    const generateStructuredData = () => {
        return {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": `${productDetails.product_name} Income By Zip Code`,
            "description": `Median Income data visualization by zip code for ${productDetails.product_name} product page`,
            "image": {
                "@type": "ImageObject",
                "contentUrl": StateImage(productDetails.product_name.replace(/ /g, '_')),
                "name": `${productDetails.product_name} Income by Zip Code`,
                "description": `Median Income data visualization by zip code for ${productDetails.product_name}`,
            },
            "url": `${window.location.origin}/income-by-zipcode/${productDetails.product_name.replace(/ /g, '_')}`
        };
    };
    const getStateName = () => {
        if (productDetails.product_name.toLowerCase() === 'usa') {
            return ' the USA';
        }
        return productDetails.product_name.replace(/_/g, ' ') || 'your state';
    };

    const handleScrollToSectionWithPadding = useCallback((ref) => {
        if (ref.current) {
            const topPosition = ref.current.getBoundingClientRect().top + window.scrollY;
            const padding = window.innerHeight * 0.075;
            
            // Add GA event for section view
            ReactGA.event({
                category: 'Content',
                action: 'Section View',
                label: ref.current.getAttribute('data-section-name') || 'Unknown Section',
            });
            
            window.scrollTo({
                top: topPosition - padding,
                behavior: 'smooth',
            });
        }
    }, []);

    const handleCheckoutWithProduct = () => {
        const itemInCart = getItem(productDetails.id); // Check if the item is already in the cart
        if (!itemInCart) {
            addItem(productDetails); // Add item only if it's not in the cart
        }
        ReactGA.event({
            category: 'Ecommerce',
            action: 'Checkout with Product',
            label: productDetails.product_name,
        });

        navigate('/user/cart'); // Navigate to the cart
    };

    const targetSampleData = useRef(null);
    const targetAllStates = useRef(null);
    const targetDataPoints = useRef(null);
    const targetFreeZipCodeList = useRef(null);

    const renderHeroSection = () => (
        <MKBox 
            component="section" 
            sx={{ 
                backgroundImage: 'linear-gradient(to right, rgba(0,119,182,0.9), rgba(0,180,216,0.9))',
                pt: { xs: 4, lg: 12 },
                pb: { xs: 4, lg: 8 },
                position: 'relative',
                mt: '-90px',
                pt: { xs: '90px', lg: '90px' }  // Increased mobile top padding
            }}
        >
            <Container maxWidth="lg">
                {/* Mobile Title - Only shows on mobile */}
                <Box sx={{ 
                    display: { xs: 'block', lg: 'none' }, 
                    mb: 3,
                    mt: { xs: 2, lg: 0 },  // Added top margin for mobile
                    px: { xs: 2, lg: 0 }    // Added horizontal padding for mobile
                }}>
                    <MKTypography
                        variant="h1"
                        sx={{
                            fontSize: { xs: '1.75rem', sm: '2rem' },  // Reduced font size for mobile
                            fontWeight: 700,
                            color: '#ffffff',
                            mb: 2,
                            lineHeight: 1.2,
                            textAlign: 'center'
                        }}
                    >
                        Income by Zip Code Data Report for {getStateName()}
                    </MKTypography>
                </Box>

                <Grid container spacing={{ xs: 2, lg: 6 }} alignItems="center">
                    {/* Content Section */}
                    <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
                        <Box sx={{ pr: { lg: 6 } }}>
                            {/* Desktop Title - Only shows on desktop */}
                            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                                <MKTypography
                                    variant="h1"
                                    sx={{
                                        fontSize: '3.5rem',
                                        fontWeight: 700,
                                        color: '#ffffff',
                                        mb: 3,
                                        lineHeight: 1.2,
                                        pt: 8,
                                    }}
                                >
                                    Income by Zip Code Data Report for {getStateName()}
                                </MKTypography>
                            </Box>

                            <MKTypography
                                variant="h4"
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.9)',
                                    lineHeight: 1.1,
                                    fontSize: { xs: '1.1rem', lg: '1.5rem' },
                                    mb: { xs: 2, lg: 3 },
                                    textAlign: { xs: 'center', lg: 'left' }
                                }}
                            >
                                Make Data-Driven Decisions with Comprehensive Zip Code Income Reports for {getStateName()}
                            </MKTypography>

                            <Box sx={{ 
                                mt: { xs: 1, lg: 2 }, 
                                textAlign: { xs: 'left', lg: 'left' },
                                px: { xs: 2, lg: 0 }
                            }}>
                                <ul style={{ 
                                    listStyle: 'none', 
                                    padding: 0,
                                    margin: 0 
                                }}>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff',
                                        fontSize: { xs: '0.9rem', lg: '1rem' }
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Identify Prime Locations Using Current Income Data by Zip Code
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Navigate Income Demographics Across Every US Zip Code
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Median & Mean Household Income by Zip Code
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Household Income Distribution by Income Brackets
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        Retirement & Social Security Income Data 
                                    </li>
                                    <li style={{ 
                                        marginBottom: '0.25rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#ffffff'
                                    }}>
                                        <span style={{ color: '#FFA500', marginRight: '0.75rem' }}>✓</span>
                                        98 Metrics with Margin of Error Calculations
                                    </li>
                                </ul>
                            </Box>

                            <Box sx={{ 
                                mb: { xs: 2, lg: 4 },
                                mt: { xs: 2, lg: 4 },
                                textAlign: { xs: 'center', lg: 'left' }
                            }}>
                                <MKButton 
                                    onClick={handleCheckoutWithProduct}
                                    variant="contained"
                                    color="success"
                                    size="large"
                                    sx={{ 
                                        py: { xs: 1.5, lg: 2 },
                                        px: { xs: 3, lg: 4 },
                                        fontSize: { xs: '1.1rem', lg: '1.25rem' },
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)'
                                        }
                                    }}
                                >
                                    Buy Data Report - ${productDetails.price ? (productDetails.price / 100) : '49'}
                                </MKButton>
                            </Box>

                            <Box sx={{ mb: 2, textAlign: { xs: 'center', lg: 'left' } }}>
                                {productDetails.product_name.toLowerCase() === 'usa' ? (
                                    <span style={{ 
                                        display: 'block', 
                                        fontSize: '0.9em',
                                        color: 'rgba(255, 255, 255, 0.9)',
                                        fontStyle: 'italic' 
                                    }}>
                                        Just Need one state? Select it from the dropdown below
                                    </span>
                                ) : (
                                    <span style={{ 
                                        display: 'block', 
                                        fontSize: '0.9em',
                                        color: 'rgba(255, 255, 255, 0.9)',
                                        fontStyle: 'italic' 
                                    }}>
                                        Need more than one state? Select all USA for all states and save big!
                                    </span>
                                )}
                            </Box>

                            <Box sx={{ mb: { xs: 2, lg: 4 } }}>
                                <StateDropDown
                                    onSelectButton={handleProductChange}
                                    products={productList}
                                    initialSet={stateName ? stateName.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) : ''}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            color: '#ffffff',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(255, 255, 255, 0.5)',
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: '#ffffff',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#ffffff',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    
                    {/* Image Section */}
                    <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '16px',
                                overflow: 'hidden',
                                boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                                p: 2,
                                width: '100%',
                                height: 0,
                                paddingTop: { 
                                    xs: '50%',  // Reduced height for mobile
                                    lg: '62.6%'
                                },
                                position: 'relative',
                                mx: 'auto',
                                maxWidth: { 
                                    xs: '100%',
                                    lg: '600px'
                                },
                                mb: { xs: 2, lg: 0 }  // Add margin bottom for mobile
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: { xs: 1, lg: 2 }
                                }}
                            >
                                <img 
                                    src={StateImage(formattedStateName.replace(/ /g, '_'))}
                                    alt={`Income data visualization for ${formattedStateName} - color-coded map showing income distribution by ZIP code.`}
                                    title={`Income Distribution Map - ${formattedStateName}`}
                                    width="800"
                                    height="600"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '12px',
                                        cursor: 'pointer'
                                    }}
                                    loading="lazy"
                                    onClick={() => navigate('/income-map')}
                                />
                            </Box>
                        </Box>

                        {/* USA Purchase Button */}
                        {productDetails.product_name.toLowerCase() !== 'usa' && (
                            <Box
                                sx={{
                                    mt: { xs: 1, lg: 2 },
                                    py: { xs: 1, lg: 2 },
                                    textAlign: 'center',
                                    transform: 'translateY(-10px)',
                                    opacity: 1,
                                    transition: 'all 0.3s ease',
                                }}
                            >
                                <MKButton
                                    onClick={handlePurchaseUSA}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{ 
                                        py: { xs: 1.5, lg: 2 },
                                        px: { xs: 3, lg: 4 },
                                        fontSize: { xs: '.75rem', lg: '1.25rem' },
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                                        '&:hover': {
                                            transform: 'translateY(-1px)',
                                            boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)'
                                        }
                                    }}
                                >
                                    Get All 50 States Data for $199
                                </MKButton>
                            </Box>
                        )}

                        {/* Navigation Buttons */}
                        <Grid container spacing={2} sx={{ mt: { xs: 1, lg: 2 } }}>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: { xs: 0.5, lg: 1 },
                                    borderRadius: '8px',
                                    backgroundColor: '#008080',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: colors.light,
                                        transform: 'translateY(-2px)'
                                    }
                                }} onClick={() => handleScrollToSectionWithPadding(targetSampleData)}>
                                    <MKTypography 
                                        variant="body2"
                                        sx={{
                                            fontWeight: 700,
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            fontSize: { xs: '0.7rem', lg: '1rem' }
                                        }}
                                    >
                                        See a Sample
                                    </MKTypography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: { xs: 0.5, lg: 1 },
                                    borderRadius: '8px',
                                    backgroundColor: '#008080',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: colors.light,
                                        transform: 'translateY(-2px)'
                                    }
                                }} onClick={() => handleScrollToSectionWithPadding(targetDataPoints)}>
                                    <MKTypography 
                                        variant="body2"
                                        sx={{
                                            fontWeight: 700,
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            fontSize: { xs: '0.7rem', lg: '1rem' }
                                        }}
                                    >
                                        See All Data Fields
                                    </MKTypography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: { xs: 0.5, lg: 1 },
                                    borderRadius: '8px',
                                    backgroundColor: '#008080',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: colors.light,
                                        transform: 'translateY(-2px)'
                                    }
                                }} onClick={() => handleScrollToSectionWithPadding(targetFreeZipCodeList)}>
                                    <MKTypography 
                                        variant="body2"
                                        sx={{
                                            fontWeight: 700,
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            fontSize: { xs: '0.7rem', lg: '1rem' }
                                        }}
                                    >
                                        Free Zip Code List
                                    </MKTypography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: { xs: 0.5, lg: 1 },
                                    borderRadius: '8px',
                                    backgroundColor: 'warning.main',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    position: 'relative',
                                }} onClick={() => navigate('/income-map')}>
                                    <MKTypography 
                                        variant="body2"
                                        sx={{
                                            fontWeight: 500,
                                            color: '#ffffff',
                                            textAlign: 'center',
                                            fontSize: { xs: '1rem', lg: '1.5rem' }
                                        }}
                                    >
                                        <MapIcon sx={{ mr: 1 }} /> View Free Interactive Map
                                    </MKTypography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderBenefitsSection = () => (
        <MKBox 
            component="section" 
            sx={{ 
                py: { xs: 4, lg: 6 },
                background: '#ffffff',
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ mb: { xs: 6, lg: 8 }, textAlign: 'center' }}>
                    <MKTypography
                        variant="h2"
                        sx={{
                            color: '#2c3e50',
                            fontWeight: 700,
                            mb: 2,
                            fontSize: {
                                xs: '2rem',
                                lg: '2.5rem'
                            }
                        }}
                    >
                        Why Choose Our Income Data?
                    </MKTypography>
                </Box>

                <Grid container spacing={4}>
                    {[
                        {
                            icon: <AttachMoneyIcon sx={{ fontSize: '2rem', color: colors.darkMedium }} />,
                            title: 'Comprehensive Coverage',
                            description: `Access detailed income brackets, median household income, and retirement data for every zip code in ${getStateName()}.`
                        },
                        {
                            icon: <NumbersIcon sx={{ fontSize: { xs: '1.75rem', lg: '2rem' }, color: colors.darkMedium }} />,
                            title: 'Latest Census Data',
                            description: 'Make decisions with the most current census data available, ensuring accuracy and relevance.'
                        },
                        {
                            icon: <PercentIcon sx={{ fontSize: { xs: '1.75rem', lg: '2rem' }, color: colors.darkMedium }} />,
                            title: 'Ready-to-Use Format',
                            description: 'Download instantly in Excel format with organized, clean data ready for analysis and integration.'
                        }
                    ].map((benefit, index) => (
                        <Grid item xs={12} lg={4} key={index}>
                            <Box
                                sx={{
                                    height: '100%',
                                    p: { xs: 1, lg: 4 },
                                    borderRadius: '16px',
                                    background: 'white',
                                    boxShadow: '0 0 20px rgba(100,100,100,0.5)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
                                    }
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        mb: 1
                                    }}
                                >
                                    {benefit.icon}
                                    <MKTypography 
                                        variant="h4" 
                                        sx={{ 
                                            color: '#2c3e50',
                                            fontWeight: 500,
                                            fontSize: {
                                                xs: '1.5rem',
                                                lg: '1.75rem'
                                            }
                                        }}
                                    >
                                        {benefit.title}
                                    </MKTypography>
                                </Box>
                                
                                <MKTypography 
                                    variant="body1" 
                                    sx={{ 
                                        color: '#2c3e50',
                                        lineHeight: 1.6,
                                        fontSize: {
                                            xs: '0.9rem',
                                            lg: '1rem'
                                        }
                                    }}
                                >
                                    {benefit.description}
                                </MKTypography>
                            </Box>
                        </Grid>
                    ))}
                    {/* Call to Action */}
                    <Grid item xs={12}>
                        <Box sx={{ 
                            p: { xs: 2, lg: 3 }, 
                            mt: { xs: 2, lg: 4 },
                            textAlign: 'center',
                            background: 'white',
                            borderRadius: '16px',
                            boxShadow: `0 4px 20px ${colors.darkest}1A`,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <MKTypography 
                                variant="h3" 
                                mb={3} 
                                sx={{ 
                                    color: colors.darkest,
                                    fontSize: {
                                        xs: '1.75rem',
                                        lg: '2.25rem'
                                    }
                                }}
                            >
                                Why Purchase From us? 
                            </MKTypography>
                            <MKTypography 
                                variant="body1" 
                                mb={2} 
                                sx={{ 
                                    color: colors.darkest,
                                    maxWidth: '100vh',
                                    lineHeight: 1.6,
                                    fontSize: {
                                        xs: '0.9rem',
                                        lg: '1rem'
                                    }
                                }}
                            >
                                Save valuable time and resources by letting us handle the complex census data extraction process. 
                                Our team has already done the heavy lifting - cleaning, organizing, and formatting the data from 
                                multiple census tables. What would typically take days to gather and process is available instantly, 
                                allowing you to focus on using the insights rather than collecting them.
                            </MKTypography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );

    const renderPricingSection = () => {
        const trackPriceClick = (price, type) => {
            ReactGA.event({
                category: 'Pricing',
                action: 'Price Click',
                label: type,
                value: parseFloat(price),
            });
        };
        
        return (
            <MKBox component="section" py={6} sx={{ background: 'white' }}>
                <Container maxWidth="lg">
                    <MKTypography variant="h2" textAlign="center" mb={5}>
                        Choose Your Data Package
                    </MKTypography>
                    
                    <Grid container spacing={4} justifyContent="center">
                        {/* State Package */}
                        <Grid item xs={12} lg={5}>
                            <Box sx={{ 
                                p: { xs: 3, lg: 4 },
                                border: '1px solid',
                                borderColor: 'grey.300',
                                borderRadius: '16px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                '&:hover': {
                                    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
                                    transform: 'translateY(-4px)',
                                    transition: 'all 0.3s ease'
                                }
                            }}>
                                <MKTypography 
                                    variant="h3" 
                                    mb={2}
                                    sx={{
                                        fontSize: {
                                            xs: '1.75rem',
                                            lg: '2.25rem'
                                        }
                                    }}
                                >
                                    {getStateName()} Package
                                </MKTypography>
                                <MKTypography 
                                    variant="h2" 
                                    color="success" 
                                    mb={3}
                                    sx={{
                                        fontSize: {
                                            xs: '2rem',
                                            lg: '2.5rem'
                                        }
                                    }}
                                >
                                    ${productDetails.price ? (productDetails.price / 100) : '49'}
                                </MKTypography>
                                <Box sx={{ mb: 3 }}>
                                    <MKTypography variant="body1" component="div">
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                                Complete {getStateName()} zip code data
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                                Income brackets & median income
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                                Retirement income data
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#4CAF50', marginRight: '0.5rem' }}>✓</span>
                                                Instant Excel download
                                            </li>
                                        </ul>
                                    </MKTypography>
                                </Box>
                                <Box sx={{ mt: 'auto' }}>
                                    <MKButton 
                                        onClick={() => {
                                            trackPriceClick('49.99', 'State Package');
                                            handleCheckoutWithProduct();
                                        }}
                                        variant="gradient" 
                                        color="success" 
                                        fullWidth
                                        sx={{ fontSize: '1.1rem', py: 1.5 }}
                                    >
                                        Get {getStateName()} Data
                                    </MKButton>
                                </Box>
                            </Box>
                        </Grid>

                        {/* USA Package */}
                        <Grid item xs={12} lg={5} >
                            <Box sx={{ 
                                p: { xs: 3, lg: 4 },
                                border: '2px solid',
                                borderColor: 'primary.main',
                                borderRadius: '16px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                '&:hover': {
                                    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
                                    transform: 'translateY(-4px)',
                                    transition: 'all 0.3s ease'
                                }
                            }}>
                                <Box sx={{
                                    position: 'absolute',
                                    top: -12,
                                    right: { xs: 16, lg: 24 },
                                    background: '#e91e63',
                                    color: 'white',
                                    px: 2,
                                    py: 0.5,
                                    borderRadius: '12px',
                                    fontWeight: 'bold',
                                    boxShadow: '0 2px 8px rgba(233, 30, 99, 0.3)',
                                    zIndex: 1
                                }}>
                                    <h4 style={{ color: 'white' }}>
                                        Best Value
                                    </h4>
                                </Box>

                                <MKTypography 
                                    variant="h3" 
                                    mb={2}
                                    sx={{
                                        fontSize: {
                                            xs: '1.75rem',
                                            lg: '2.25rem'
                                        }
                                    }}
                                >
                                    Complete USA Package
                                </MKTypography>
                                <MKTypography 
                                    variant="h2" 
                                    color="primary" 
                                    mb={3}
                                    sx={{
                                        fontSize: {
                                            xs: '2rem',
                                            lg: '2.5rem'
                                        }
                                    }}
                                >
                                    $199
                                </MKTypography>
                                <Box sx={{ mb: 3 }}>
                                    <MKTypography variant="body1" component="div">
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                                All 50 states included
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                                Complete nationwide coverage
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                                All income metrics included
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                                Bulk discount savings
                                            </li>
                                            <li style={{ marginBottom: '.1rem', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ color: '#e91e63', marginRight: '0.5rem' }}>✓</span>
                                                Priority support
                                            </li>
                                        </ul>
                                    </MKTypography>
                                </Box>
                                <Box sx={{ mt: 'auto' }}>
                                    <MKButton 
                                        onClick={handlePurchaseUSA}
                                        variant="gradient" 
                                        color="primary" 
                                        fullWidth
                                        sx={{ fontSize: '1.1rem', py: 1.5 }}
                                    >
                                        Get Complete USA Data
                                    </MKButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Money-back Guarantee */}
                    <Box sx={{ 
                        mt: { xs: 4, lg: 6 },
                        p: { xs: 2, lg: 3 },
                        textAlign: 'center',
                        border: '1px solid',
                        borderColor: 'grey.300',
                        borderRadius: '8px',
                        maxWidth: '600px',
                        mx: 'auto'
                    }}>
                        <MKTypography 
                            variant="h5" 
                            mb={2}
                            sx={{
                                fontSize: {
                                    xs: '1.25rem',
                                    lg: '1.5rem'
                                }
                            }}
                        >
                            100% Money-Back Guarantee
                        </MKTypography>
                        <MKTypography 
                            variant="body1" 
                            color="text.secondary"
                            sx={{
                                fontSize: {
                                    xs: '0.9rem',
                                    lg: '1rem'
                                }
                            }}
                        >
                            Not satisfied with your purchase? We offer a full refund, no questions asked. 
                            Your satisfaction is our top priority.
                        </MKTypography>
                    </Box>


                    <Box sx={{ 
                        textAlign: 'center', 
                        mt: { xs: 3, lg: 4 },
                        mb: 2 
                    }}>
                        <MKTypography
                            variant="body1"
                            onClick={handleMapNavigation}
                            sx={{
                                color: colors.darkMedium,
                                textDecoration: 'none',
                                display: 'inline-flex',
                                alignItems: 'center',
                                transition: 'color 0.3s',
                                cursor: 'pointer',
                                fontSize: {
                                    xs: '0.9rem',
                                    lg: '1rem'
                                },
                                '&:hover': {
                                    color: colors.darkest
                                }
                            }}
                        >
                            Want to explore the data visually? Check out our Interactive Income Maps →
                        </MKTypography>
                    </Box>
                </Container>
            </MKBox>
        );
    };

    const handleMapNavigation = () => {
        // Add GA event for map navigation
        ReactGA.event({
            category: 'Navigation',
            action: 'Map View Click',
            label: formattedStateName,
        });
        
        navigate('/income-map');
    };

    // Add page view tracking in useEffect
    useEffect(() => {
        // Track page view with state name
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: `${formattedStateName} Income Data by ZIP Code`
        });
    }, [formattedStateName]);

    // Add component mount tracking
    useEffect(() => {
        ReactGA.event({
            category: 'Page',
            action: 'Component Mount',
            label: `Income By Zip - ${formattedStateName}`,
        });
        
        // Add timing tracking for product data load
        const startTime = Date.now();
        
        return () => {
            const endTime = Date.now();
            ReactGA.event({
                category: 'Performance',
                action: 'Product Data Load Time',
                label: formattedStateName,
                value: Math.round((endTime - startTime) / 1000), // Convert to seconds
            });
        };
    }, [formattedStateName]);

    return (
        <>
            <Helmet>
                <title>{`${formattedStateName} Income Data by ZIP Code | Most Recent Census Data`}</title>
                <meta name="description" content={`${formattedStateName}'s detailed analyses, includes median and average incomes, retirement household distributions, and over 98 key metrics, all conveniently compiled in an Excel report.`} />
                <link rel="canonical" href={`${window.location.origin}/income-by-zipcode/${stateName}`} />
                
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="product" />
                <meta property="og:title" content={`${formattedStateName} Income Data by ZIP Code | Latest Census Data`} />
                <meta property="og:description" content={`Download complete ${formattedStateName} income data by ZIP code. Includes median income, retirement income, and income brackets for all ZIP codes.`} />
                <meta property="og:image" content={StateImage(stateName)} />
                <meta property="og:url" content={`${window.location.origin}/income-by-zipcode/${stateName}`} />
                
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${formattedStateName} ZIP Code Income Data`} />
                <meta name="twitter:description" content={`Get comprehensive income data for all ${formattedStateName} ZIP codes. Instant Excel download with 98+ metrics.`} />
                <meta name="twitter:image" content={StateImage(stateName)} />
                
                {/* Additional SEO tags */}
                <meta name="robots" content="index, follow" />
                <meta name="price" content={getBasePrice(stateName)} />
                
                {/* Product Schema */}
                <script type="application/ld+json">
                    {JSON.stringify([
                        {
                            "@context": "https://schema.org",
                            "@type": "Product",
                            "name": `${formattedStateName} Income Data by ZIP Code`,
                            "description": `Complete income and demographic data for all ZIP codes in ${formattedStateName}. Includes median household income, retirement income, and income brackets.`,
                            "image": StateImage(stateName),
                            "url": `${window.location.origin}/income-by-zipcode/${stateName}`,
                            "brand": {
                                "@type": "Brand",
                                "name": "TerraGrasp"
                            },
                            "offers": {
                                "@type": "Offer",
                                "price": getBasePrice(stateName),
                                "priceCurrency": "USD",
                                "availability": "https://schema.org/InStock",
                                "seller": {
                                    "@type": "Organization",
                                    "name": "TerraGrasp"
                                }
                            },
                            "additionalProperty": [
                                {
                                    "@type": "PropertyValue",
                                    "name": "Data Points",
                                    "value": "98+ metrics"
                                },
                                {
                                    "@type": "PropertyValue",
                                    "name": "Format",
                                    "value": "Excel Download"
                                },
                                {
                                    "@type": "PropertyValue",
                                    "name": "Data Source",
                                    "value": "Most recent US Census"
                                }
                            ]
                        },
                        {
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": `${formattedStateName} Income Data by ZIP Code`,
                            "description": `Detailed income data visualization and analysis for ${formattedStateName}. Access comprehensive ZIP code level income metrics and demographics.`,
                            "image": {
                                "@type": "ImageObject",
                                "contentUrl": StateImage(stateName),
                                "name": `${formattedStateName} Income by ZIP Code Map`,
                                "description": `Income data visualization map for ${formattedStateName}`
                            },
                            "url": `${window.location.origin}/income-by-zipcode/${stateName}`,
                            "isPartOf": {
                                "@type": "WebSite",
                                "name": "TerraGrasp",
                                "url": window.location.origin
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": window.location.origin
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Income by ZIP Code",
                                        "item": `${window.location.origin}/income-by-zipcode`
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": `${formattedStateName} Data`,
                                        "item": `${window.location.origin}/income-by-zipcode/${stateName}`
                                    }
                                ]
                            }
                        }
                    ])}
                </script>
            </Helmet>

            {/* Hero Section */}
            {renderHeroSection()}

            {/* Specific State Data */}
            <SpecificStateData stateName={stateName} colors={colors} stateData={stateData}/>
            
            {/* Sample Data Section */}
            <SampleDataSection 
                DownloadSample={DownloadSample} 
                targetSampleData={targetSampleData} 
                colors={colors} 
            />

            {/* Specific State Data Bottom */}
            <SpecificStateDataBottom stateName={stateName} colors={colors} stateData={stateData}/>

            {/* Free Zip Code List */}
            <Box sx={{ 
                mt: { xs: 1 },
                p: { xs: 2, lg: 3 },
                textAlign: 'center',
                border: '1px solid',
                borderColor: 'grey.300',
                borderRadius: '8px',
                    maxWidth: '600px',
                    mx: 'auto'
                }}>
                    <MKTypography 
                        variant="h5" 
                        mb={2}
                        ref={targetFreeZipCodeList}
                        sx={{
                            fontSize: {
                                xs: '1.25rem',
                                lg: '1.5rem'
                            }
                        }}
                    >
                        Download a Free Zip Code List for {getStateName()}
                    </MKTypography>
                    <MKTypography 
                        variant="body1" 
                        color="text.secondary"
                        sx={{
                            fontSize: {
                                xs: '0.9rem',
                                lg: '1rem'
                            }
                        }}
                    >
                        Just need a list of Zip Codes? Download our free zipcode list for {getStateName()}.
                         The Zip Code list comes with each zipcodes city, state, and county. In an Excel format.
                    </MKTypography>
                    <MKButton 
                    onClick={() => DownloadZipCodeList(stateName)} 
                    variant="gradient" 
                    color="warning" 
                    size="large"
                    sx={{ 
                        fontSize: { xs: '1rem', lg: '1.1rem' },
                        width: '80%',
                        mb: 3,
                        py: { xs: 1.5, lg: 2 }
                    }}
                >
                    Download Free Zip Code List
                </MKButton>

                </Box>
            {/* Pricing Section */}
            {renderPricingSection()}


            {/* Schema Data Section - Keep if still needed */}
            <MKBox component="section" py={1} sx={{ textAlign: "center", background: colors.lightest, mx: "auto", color: 'black' }}>
                <Grid container flexDirection="column" alignItems="center"
                    sx={{ textAlign: "center" }}
                >
                    <div style={{ textAlign: 'Center', maxWidth: '70%', marginBottom: '2vh'}} ref={targetDataPoints}>
                        <MKTypography variant="h3" mb={2} color='black'>
                            See All Data Fields
                        </MKTypography>
                        <IncomeSchema />
                    </div>
                </Grid>
            </MKBox>
                        {/* Benefits Section */}
                        {renderBenefitsSection()}

            {/* Important Details Section */}
            <MKBox component="section" py={2} sx={{ textAlign: "center", mx: "auto" }}>
                <Container>
                    <Grid container flexDirection="column" alignItems="center">
                        <Box sx={{ maxWidth: '70%', my: 4 }}>
                            <MKTypography variant="h3" mb={3}>
                                Important Data Details
                            </MKTypography>
                            <MKTypography variant="body1" mb={3}>
                                When you purchase data for {getStateName()}, you may notice that some zip codes are missing data for certain metrics or entirely.
                                This can be due to various factors such as data suppression for privacy protection, non-response from individuals, or data collection issues.
                                These missing values originate directly from the census and are unavailable to the public.
                            </MKTypography>
                            <MKTypography variant="body1">
                                The data is sourced from the 2023 publicly available U.S. Census data, which is the most recent dataset available from the census bureau.
                                Invest in your {getStateName()}-specific income data today and empower your decisions with accuracy and comprehensive insights.
                            </MKTypography>
                        </Box>
                    </Grid>
                </Container>
            </MKBox>
          <MKBox component="section" sx={{ textAlign: "center", mx: "auto" }}  ref={targetAllStates}>
            <StateLinks allStates={allStates} getStateUrl={getStateUrl} />
          </MKBox>
        </>
    );
};
export default ProductDetail;

