import React, { useState, useEffect } from "react";
import axios from "axios";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import StateSVGComponent from 'components/images/StateSVGs';
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";



function SubscriptionPage() {
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [selectedState, setSelectedState] = useState({ name: 'Arizona' });

  // Pre-populated states list
  const statesList = [
    { name: "Alabama"},
    { name: "Alaska"},
    { name: "Arizona"},
    { name: "Arkansas"},
    { name: "California"},
    { name: "Colorado"},
    { name: "Connecticut"},
    { name: "Delaware"},
    { name: "Florida"},
    { name: "Georgia"},
    { name: "Hawaii"},
    { name: "Idaho"},
    { name: "Illinois"},
    { name: "Indiana"},
    { name: "Iowa"},
    { name: "Kansas"},
    { name: "Kentucky"},
    { name: "Louisiana"},
    { name: "Maine"},
    { name: "Maryland"},
    { name: "Massachusetts"},
    { name: "Michigan"},
    { name: "Minnesota"},
    { name: "Mississippi"},
    { name: "Missouri"},
    { name: "Montana"},
    { name: "Nebraska"},
    { name: "Nevada"},
    { name: "New Hampshire"},
    { name: "New Jersey"},
    { name: "New Mexico"},
    { name: "New York"},
    { name: "North Carolina"},
    { name: "North Dakota"},
    { name: "Ohio"},
    { name: "Oklahoma"},
    { name: "Oregon"},
    { name: "Pennsylvania"},
    { name: "Rhode Island"},
    { name: "South Carolina"},
    { name: "South Dakota"},
    { name: "Tennessee"},
    { name: "Texas"},
    { name: "Utah"},
    { name: "Vermont"},
    { name: "Virginia"},
    { name: "Washington"},
    { name: "West Virginia"},
    { name: "Wisconsin"},
    { name: "Wyoming"},
  ];


  const handleSubscribe = async (tier,stateName) => {
    setIsCheckoutLoading(true);
    console.log(tier,"\n");
    console.log(stateName);
    try {
      const stateName = tier === 'state' ? selectedState.name : (tier === 'country' ? 'usa' : null);

      console.log('Creating checkout session for:', { tier, stateName }); // Debug log

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/create-checkout-session`,
        {
          state_name: stateName
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data && response.data.checkout_url) {
        window.location.href = response.data.checkout_url;
      } else {
        throw new Error('No checkout URL received from server');
      }
    } catch (error) {
      console.error("Failed to create checkout session. Please try again.");
    } finally {
      setIsCheckoutLoading(false);
    }
  };

  // Define the features for each tier
  const freeTierFeatures = [
    { text: "Basic metrics at the tract level", included: true },
    { text: "Access to social and demographic data", included: true },
    { text: "Compare data across geographic levels", included: true },
    { text: "Basic data visualization tools", included: true },
    { text: "Advanced filtering and analysis", included: false },
    { text: "Interactive map visualization", included: false },
    { text: "Access to all US states data", included: false },
    { text: "Priority support", included: false }
  ];

  const stateTierFeatures = [
    { text: "500+ metrics at the tract level", included: true },
    { text: "Access to social and demographic data", included: true },
    { text: "Compare data across geographic levels", included: true },
    { text: "Basic data visualization tools", included: true },
    { text: "Advanced filtering and analysis", included: true },
    { text: "Interactive map visualization", included: true },
    { text: "Access to all US states data", included: false },
    { text: "Priority support", included: false }
  ];

  const countryTierFeatures = [
    { text: "View basic metrics at the tract level", included: true },
    { text: "Access to social and demographic data", included: true },
    { text: "Compare data across geographic levels", included: true },
    { text: "Basic data visualization tools", included: true },
    { text: "Advanced filtering and analysis", included: true },
    { text: "Interactive map visualization", included: true },
    { text: "Access to all US states data", included: true },
    { text: "Priority support", included: true }
  ];

  const FeaturesList = React.memo(({ features }) => {
    return (
      <MKBox p={{ xs: 2, md: 4 }} sx={{ flex: 1 }}>
        {features.map((feature, index) => (
          <MKBox 
            key={index} 
            display="flex" 
            alignItems="center" 
            mb={{ xs: 1, md: 2 }}
            sx={{
              borderBottom: index < features.length - 1 ? '1px dashed rgba(0,0,0,0.1)' : 'none',
              pb: { xs: 1, md: 2 },
              minHeight: '16px'
            }}
          >
            <Icon 
              sx={{ 
                color: feature.included ? 'success.main' : 'error.main',
                mr: 1,
                fontSize: { xs: '1.2rem', md: '1.5rem' }
              }}
            >
              {feature.included ? 'check_circle' : 'cancel'}
            </Icon>
            <MKTypography variant="body2" color="#000000" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
              {feature.text}
            </MKTypography>
          </MKBox>
        ))}
      </MKBox>
    );
  });
  const handleTryNow = () => {
      window.scrollTo({ top: 900, behavior: 'smooth' });
  };
  function BuyNowButton(variant, text, onClick, widthPercentage = '80%', textColor = '#FFFFFF', bgColor = 'grey.400') {
    return (
      <MKBox p={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <MKButton
          variant={variant}
          onClick={onClick}
          sx={{ 
            fontSize: '1.5rem',
            width: widthPercentage,
            color: textColor,
            backgroundColor: bgColor,
            '&:hover': {
              backgroundColor: bgColor,
            }
          }}
        >
          {text}
        </MKButton>
      </MKBox>
    );
  }

  return (
    <MKBox
      minHeight="100vh"
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: '20px',
        paddingBottom: '90px'
      }}
    >
      <Container>
        <Grid container spacing={{ xs: 4, md: 3 }} justifyContent="center">
          {/* Free Tier */}
          <Grid item xs={12} md={4} sx={{ mb: { xs: 2, md: 0 } }}>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
              border: '1px solid rgba(0,0,0,0.1)',
              backgroundColor: 'white'
            }}>
              {/* Header */}
              <MKBox 
                p={{ xs: 1.5, md: 2 }} 
                sx={{ 
                  backgroundColor: 'grey.400',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                <MKTypography variant="h4" color="grey.900" fontWeight="bold" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                  Limited Access
                </MKTypography>
                <MKTypography variant="h6" color="grey.900" fontWeight="bold" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                  Limited Metrics
                </MKTypography>
              </MKBox>
              
              {/* Price */}
              <MKBox p={{ xs: 2, md: 3 }} textAlign="center" sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center',
                height: '100%'
              }}>
                <MKTypography variant="h2" color="dark" sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}>
                  $0
                </MKTypography>
                <MKTypography variant="body2" color="text" opacity={0.8} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
                  Forever free
                </MKTypography>
              </MKBox>
              <MKBox px={{ xs: 2, md: 3 }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ mb: 1, height: { xs: '80px', md: '100px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <StateSVGComponent 
                        stateName={selectedState?.name || "Arizona"} 
                        size={80} 
                        color={"#ced4da"} 
                      />
                    </Box>
                  </Box>
              </MKBox>
              {BuyNowButton("gradient", "Try Now", () => handleTryNow(), '80%', '#000000')}
              
              {/* Features */}
              <FeaturesList features={freeTierFeatures} />              
            </Card>
          </Grid>
          
          {/* State Tier */}
          <Grid item xs={12} md={4} sx={{ mb: { xs: 2, md: 0 } }}>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 8px 20px rgba(0,0,0,0.15)',
              border: '1px solid rgba(0,0,0,0.1)',
              backgroundColor: 'white',
              transform: 'scale(1.05)',
              zIndex: 2,
              position: 'relative'
            }}>
              {/* Header */}
              <MKBox 
                p={{ xs: 1.5, md: 2 }} 
                sx={{ 
                  backgroundColor: 'info.main',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                <MKTypography variant="h4" color="white" fontWeight="bold" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                  State Plan
                </MKTypography>
                <MKTypography variant="h6" color="white" fontWeight="bold" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                  500+ Metrics
                </MKTypography>
              </MKBox>
              
              {/* Price */}
              <MKBox p={{ xs: 2, md: 3 }} textAlign="center">
                <MKTypography variant="h2" color="dark" sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}>
                  $68
                </MKTypography>
                <MKTypography variant="body2" color="#000000" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
                  per year / per state
                </MKTypography>
              </MKBox>
              
              {/* State Dropdown */}
              <MKBox px={{ xs: 2, md: 3 }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ mb: 1, height: { xs: '80px', md: '100px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <StateSVGComponent 
                        stateName={selectedState?.name || "Arizona"} 
                        size={80} 
                        color={"#1A73E8"} 
                      />
                    </Box>
                    <Select
                      value={selectedState?.name || "Arizona"}
                      onChange={(event) => {
                        const state = statesList.find(s => s.name === event.target.value);
                        setSelectedState(state);
                      }}
                      fullWidth
                      IconComponent={() => (
                        <KeyboardArrowDownIcon sx={{
                          position: 'absolute',
                          right: '8px',
                          color: '#666666',
                          fontSize: '24px',
                          pointerEvents: 'none'
                        }} />
                      )}
                      sx={{
                        height: '40px',
                        cursor: 'pointer',
                        '& .MuiSelect-select': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: { xs: '0.9rem', md: '1.1rem' },
                          fontWeight: 500,
                          paddingRight: '40px !important',
                          backgroundColor: '#f8f9fa',
                          '&:hover': {
                            backgroundColor: '#f0f1f2'
                          }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0.2)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0.4)',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'info.main',
                        }
                      }}
                    >
                      {statesList.map((state) => (
                        <MenuItem 
                          key={state.abbreviation} 
                          value={state.name}
                          sx={{
                            justifyContent: 'center',
                            fontSize: { xs: '0.9rem', md: '1.1rem' }
                          }}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
              </MKBox>
              {/* Button */}
              {BuyNowButton("gradient", "Buy Now", () => handleSubscribe('state', selectedState?.name), '80%', '#FFFFFF', 'info.main')}
              {/* Features */}
              <FeaturesList features={stateTierFeatures} />
            </Card>
          </Grid>
          
          {/* Country Tier */}
          <Grid item xs={12} md={4} sx={{ mb: { xs: 2, md: 0 } }}>
            <Card sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
              border: '1px solid rgba(0,0,0,0.1)',
              backgroundColor: 'white'
            }}>
              {/* Header */}
              <MKBox 
                p={{ xs: 1.5, md: 2 }} 
                sx={{ 
                  backgroundColor: 'warning.main',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                <MKTypography variant="h4" color="white" fontWeight="bold" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                  USA Plan
                </MKTypography>
                <MKTypography variant="h6" color="white" fontWeight="bold" sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
                  500+ Metrics
                </MKTypography>
              </MKBox>
              
              {/* Price */}
              <MKBox p={{ xs: 2, md: 3 }} textAlign="center">
                <MKTypography variant="h2" color="dark" sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}>
                  $269
                </MKTypography>
                <MKTypography variant="body2" color="text" opacity={0.8} sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
                  per year / entire country
                </MKTypography>
                <Box sx={{ height: '8px' }} />
                <StateSVGComponent 
                  stateName={"USA"} 
                  size={120} 
                  color={"#fb8c00"}
                />
              </MKBox>
              {BuyNowButton("gradient", "Buy Now", () => handleSubscribe('country'), '80%', '#FFFFFF', 'warning.main')}
              <FeaturesList features={countryTierFeatures} />
            </Card>
          </Grid>
        </Grid>
        
        {/* Money-back guarantee */}
        <Grid container justifyContent="center" mt={{ xs: 4, md: 6 }}>
          <Grid item xs={12} md={8} textAlign="center">
            <MKTypography variant="body2" color="text" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
              All paid plans come with a 30-day money-back guarantee. No questions asked.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SubscriptionPage;
