// ForgotPassword.js

import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [formData, setFormData] = React.useState({email: ''})

  const onSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true);

    axios.post(`${process.env.REACT_APP_API_URL}/request_password_change/${formData.email}`)
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            setSuccess(true);
            setSubmitted(true);
            setResendDisabled(true);
        // Disable the button for 20 seconds after the form is submitted
        setTimeout(() => setResendDisabled(false), 10000);
          }
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response && error.response.status === 409) {
            setErrorMessage("There was an error logging in! Check that you are using the correct password or Email");
        } else {
            setErrorMessage("There was an error logging in! Check that you are using the correct password or Email"); // Generic error message
        }
      })
  };

  // Reset the button's disabled state when the component is unmounted
  useEffect(() => {
    return () => setResendDisabled(false);
  }, []);

  return (
    <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
      <MKBox width="100%" mx="auto" pt={5}>
        <Grid container spacing={1} rowSpacing={5} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              pt={2.5}
              pb={2.875}
              px={2.5}
              textAlign="center"
              lineHeight={1}
            >
              <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Forgot Password
              </MKTypography>
              <MKTypography variant="button" color="white">
                Request a reset link
              </MKTypography>
            </MKBox>
            {submitted && 
            <>
              <MKBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              mx={2}
              mt={3}
              pt={2}
              pb={2}
              px={2.5}
              textAlign="center"
              lineHeight={1}
            >
                <MKTypography variant="button" color="white">
                An email has been sent to you with password reset instructions. 
                Please check your email. If you have not receive an email in 
                the next few minutes please check back here and request a new link. 
                Thank you!
                </MKTypography>
                <MKBox mt={2} mb={1}>
                  <MKButton variant="gradient" color="info" disabled={resendDisabled} onClick={onSubmit}>
                    {resendDisabled ? 'Check your email...' : "Didn't receive an email? Click to resend"}
                  </MKButton>
                </MKBox>
              </MKBox>
                
              </>
              }
            <MKBox p={3}>
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput 
                    type="email"
                    label="Email"
                    fullWidth value={formData.email}
                    onChange={(e)=>setFormData({...formData, email: e.target.value})}
                    required />
                </MKBox>
                <MKBox mt={2} mb={1}>
                  <MKButton variant="gradient" color="info" fullWidth onClick={onSubmit} disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Reset Email'} 
                  </MKButton>
                </MKBox>
                <MKBox mt={1} textAlign="center">
                  <MKTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MKTypography
                      component={Link}
                      to="/user/sign-up"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>

  );
};

export default ForgotPassword;
