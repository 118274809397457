import React, { useState, useEffect, useRef, useMemo, createContext } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap, ZoomControl, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import PropTypes from 'prop-types';
import mapColors from './map_colors.json';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Link } from 'react-router-dom';
import MetricDisplay from './MetricDisplay';
import { Box } from '@mui/material';
import ColorControls from './ColorControls';
import MetricSelection from './MetricSelection';
import MetricTypeSelector from './MetricTypeSelector';
import LocationMetricDisplayBar from './LocationMetricDisplayBar';
import TutorialOverlay from './TutorialOverlay';
import MetricBoxPlot from './MetricBoxPlot';
import TractStyler from './TractStyler';
import ColorRangeSlider from './ColorRangeSlider';
// Create a context for selected metrics
export const MetricsContext = createContext();


const StateMap = ({ initialState, initialMetrics, hierarchyType = 'DP03', demo = false, metricsByType, panel = true }) => {
  // Define default metrics for each hierarchyType
  const defaultMetricsByType = {
    
    DP02: [{ id: 'DP02_0113', label: 'Language Spoken At Home English Only', item_mapping: ["DP02_0113E","DP02_0113M","DP02_0113PE","DP02_0113PM"] }],
    DP03: [{ id: 'DP03_0063', label: 'Mean Household Income (dollars)', item_mapping: ["DP03_0063E","DP03_0063M"] }],
    DP04: [{ id: 'DP04_0091', label: 'Housing Units With A Mortgage', item_mapping: ["DP04_0091E","DP04_0091M","DP04_0091PE","DP04_0091PM"] }],
    DP05: [{ id: 'DP05_0004', label: 'Sex Ratio (males Per 100 Females)', item_mapping: ["DP05_0004E","DP05_0004M","DP05_0004PE","DP05_0004PM"] }],
  };

  // Determine initial metrics
  const initialMetricsToUse = initialMetrics?.length > 0 ? initialMetrics : defaultMetricsByType[hierarchyType];

  const [selectedMetrics, setSelectedMetrics] = useState(initialMetricsToUse);

  // Add getMetricTypeIndex at the top of the component
  const getMetricTypeIndex = (type) => {
    switch (type) {
      case 'E': return 0;  // Estimate
      case 'M': return 1;  // Margin of Error
      case 'PE': return 2; // Percentage Estimate
      case 'PM': return 3; // Percentage Margin of Error
      default: return 0;   // Default to Estimate
    }
  };

  const authHeader = useAuthHeader();
  const mapRef = useRef(null);
  const lineColor = ['#FF0000', '#000000']
  const geoJsonLayerRef = useRef(null);

  // user selections
  const [selectedState, setSelectedState] = useState(initialState || '');
  const [MetricHierarchy, setMetricHierarchy] = useState(null);
  const [selectedHierarchyType, setSelectedHierarchyType] = useState(hierarchyType || 'DP03');
  const [selectedColorGradient, setSelectedColorGradient] = useState(Object.keys(mapColors.list_of_5)[0] || 'red_to_green');
  const [selectedMetricType, setSelectedMetricType] = useState('E'); // Default paint gradient
  const [primaryMetric, setPrimaryMetric] = useState(null);
  
  // state flags (is something open or closed)
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [isPanelVisible, setIsPanelVisible] = useState(panel);
  const [isLoadingTracts, setIsLoadingTracts] = useState(false);
  const [showMetricsSelection, setShowMetricsSelection] = useState(false);
  const [isColorControlsCollapsed, setIsColorControlsCollapsed] = useState(false);
  const [showBoxPlot, setShowBoxPlot] = useState(false);


  // Map vars
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [tractGeoJson, setTractGeoJson] = useState(null);
  const [countyGeoJson, setCountyGeoJson] = useState(null);
  const [mapDefinitions, setMapDefinitions] = useState(null); // this is like state centroid and range
  const [error, setError] = useState(null);
  
  const [viewMode, setViewMode] = useState('counties');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [currentCountyId, setCurrentCountyId] = useState(null);
  const [selectedTractId, setSelectedTractId] = useState(null);

  const [opacitySelected, setOpacitySelected] = useState(0.1);
  const [opacityUnselected, setOpacityUnselected] = useState(0.3);
  const [opacityColorGradient, setOpacityColorGradient] = useState(0.3);

  const [metricsData, setMetricsData] = useState({
    headers: [],
    data: {},  // This will store county_tract_data
    county_data: {},
    default_metric_types: {}  // Map of metric ID to default type
  });

  //tutorial vars
  const [showTutorial, setShowTutorial] = useState(demo);
  const [tutorialStep, setTutorialStep] = useState(1);
  
  //color range vars
  const [countyMetricRange, setCountyMetricRange] = useState({ min: null, max: null });
  const [tractMetricRange, setTractMetricRange] = useState({ min: null, max: null });
  const [countyColorRangeValues, setCountyColorRangeValues] = useState(null);
  const [tractColorRangeValues, setTractColorRangeValues] = useState(null);


  // =====================================================================
  // Data Fetching
  // =====================================================================
  
  useEffect(() => {
    // This effect initializes the map by fetching map definitions, county geometries,
    // and initial metrics data. It sets up the base map data needed for displaying
    // counties and metrics when a state is selected. The effect runs when selectedState 
    // changes to load data for the newly selected state.
    const fetchMapDefinitions = async () => {
      try {
        const definitionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/utils/state_map_definitions`);
        setMapDefinitions(definitionsResponse.data);

        if (selectedState) {  // Only fetch if we have a selectedState
          const geoJsonResponse = await axios.get(`${process.env.REACT_APP_API_URL}/geometries/counties/${selectedState}`);
          setCountyGeoJson(geoJsonResponse.data);
          setGeoJsonData(geoJsonResponse.data);
        }
        
        // Determine which metrics to use
        const metricsToUse = initialMetrics?.length > 0 ? initialMetrics : initialMetricsToUse;
        
        if (metricsToUse?.length > 0) {
          // Set the first metric as primary metric
          setPrimaryMetric(metricsToUse[0]);
          await fetchMetricsData(metricsToUse);
        }
      } catch (err) {
        console.error('Error in fetchMapDefinitions:', err);
        setError(err.message);
      }
    };

    fetchMapDefinitions();
  }, [selectedState]);


  // This effect fetches the metric hierarchy data for the selected hierarchy type.
  // It runs when selectedHierarchyType changes to load the hierarchy data for the 
  // newly selected hierarchy type.
  useEffect(() => {
    const fetchHierarchyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/utils/metric-hierarchies/${selectedHierarchyType}`
        );
        setMetricHierarchy(response.data.categories);
      } catch (error) {
        console.error('Error fetching hierarchy data:', error);
      }
    };

    fetchHierarchyData();
  }, [selectedHierarchyType]);


  // This function fetches the metrics data for the selected metrics.
  // It runs when the selectedMetrics change to load the metrics data for the 
  // newly selected metrics.
  const fetchMetricsData = async (metrics) => {
    if (!selectedState) return;
    
    try {
        const endpoint = demo 
            ? `${process.env.REACT_APP_API_URL}/geometries/demo_state_metrics/${selectedState}`
            : `${process.env.REACT_APP_API_URL}/geometries/state_metrics/${selectedState}`;
        
        const config = demo ? {} : {
            headers: {
                Authorization: authHeader
            }
        };
        
        const response = await axios.post(
            endpoint,
            {
                Metric_ids: metrics.flatMap(metric => metric.item_mapping)
            },
            config
        );

        // Fetch data from each URL returned in the response
        const metricPromises = Object.entries(response.data.metrics).map(async ([baseMetric, url]) => {
            const metricResponse = await axios.get(url);
            return metricResponse.data;
        });

        const metricsResults = await Promise.all(metricPromises);
        
        // Merge all metric data
        metricsResults.forEach(metricData => {
            mergeMetricsData(metricData);
        });

        // Update primary metric with default type if needed
        if (primaryMetric) {
          const defaultType = metricsData.default_metric_types[primaryMetric.id];
          if (defaultType) {
            setSelectedMetricType(defaultType);
          }
        } else if (metrics.length > 0) {
          setPrimaryMetric(metrics[0]);
          const defaultType = metricsData.default_metric_types[metrics[0].id];
          if (defaultType) {
            setSelectedMetricType(defaultType);
          }
        }
    } 
    catch (error) {
    }
  };
  // =====================================================================
  // Data Merge and Storage Logic
  // =====================================================================

  const mergeMetricsData = (newData) => {
    setMetricsData(prevData => {
      // If no previous data, initialize with the new data
      if (!prevData.headers.length) {
        return {
          headers: newData.headers,
          data: newData.county_tract_data || {},
          county_data: newData.county_data || {},
          default_metric_types: {
            [newData.metric]: newData.default_metric_type
          }
        };
      }

      // Merge headers
      const existingHeadersSet = new Set(prevData.headers);
      const newHeaders = [...prevData.headers];
      const headerMapping = {};
      
      newData.headers.forEach((header, index) => {
        if (!existingHeadersSet.has(header)) {
          headerMapping[header] = newHeaders.length;
          newHeaders.push(header);
        } else {
          headerMapping[header] = prevData.headers.indexOf(header);
        }
      });

      // Merge county tract data
      const mergedData = { ...prevData.data };
      
      // Process tract-level data from county_tract_data
      if (newData.county_tract_data) {
        Object.entries(newData.county_tract_data).forEach(([countyId, tractData]) => {
          if (!mergedData[countyId]) {
            mergedData[countyId] = {};
          }
          
          Object.entries(tractData).forEach(([tractId, values]) => {
            if (!mergedData[countyId][tractId]) {
              mergedData[countyId][tractId] = new Array(newHeaders.length).fill(null);
            }
            
            if (Array.isArray(values)) {
              values.forEach((value, index) => {
                const targetIndex = headerMapping[newData.headers[index]];
                if (targetIndex !== undefined) {
                  mergedData[countyId][tractId][targetIndex] = value;
                }
              });
            }
          });
        });
      }

      // Merge county data
      const mergedCountyData = { ...prevData.county_data };
      if (newData.county_data) {
        Object.entries(newData.county_data).forEach(([countyId, countyValues]) => {
          // Initialize array if it doesn't exist
          if (!mergedCountyData[countyId]) {
            mergedCountyData[countyId] = new Array(newHeaders.length).fill(null);
          }
          
          // If the new data is an array, map it to the correct positions
          if (Array.isArray(countyValues)) {
            countyValues.forEach((value, index) => {
              const targetIndex = headerMapping[newData.headers[index]];
              if (targetIndex !== undefined) {
                mergedCountyData[countyId][targetIndex] = value;
              }
            });
          }
        });
      }

      // Update default metric types
      const updatedDefaultMetricTypes = {
        ...prevData.default_metric_types,
        [newData.metric]: newData.default_metric_type
      };

      return {
        headers: newHeaders,
        data: mergedData,
        county_data: mergedCountyData,
        default_metric_types: updatedDefaultMetricTypes
      };
    });
  };


  // =====================================================================
  // Initial Map Setup and Map Functionality
  // =====================================================================

  const mapSetup = {
    "initialZoom": 7,
    "minZoom":  2.5,
    "maxZoom":  20,
    "maxBoundsViscosity": 1.0,
    "maxBounds":  L.latLngBounds(
      [18.905547-2, -195.000000+2], // Southwest coordinates
      [71.341324+2, -66.934570+2]   // Northeast coordinates
    )
  }


  // Use useMemo to compute initialCenter
  const initialCenter = useMemo(() => {
    if (selectedState && mapDefinitions) {
      const stateKey = selectedState.toLowerCase().replace(/ /g, '_');
      const def = mapDefinitions[stateKey];
      if (def) {
        return [def.centroid_lat, def.centroid_long];
      }
    }
    return [39.8283, -98.5795]; // Default center of US
  }, [mapDefinitions, selectedState]);



  // Add this useEffect hook
  useEffect(() => {
    if (mapRef.current) {
      // setTimeout(() => {
        mapRef.current.invalidateSize();
      // }, 100); // Delay to allow for CSS transition
    }
  }, [isPanelVisible]);

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  // Helper function to get popup position
  const getPopupPosition = (feature) => {
    // Try to get coordinates from different possible properties
    if (feature.properties.centroid_lat && feature.properties.centroid_long) {
      return [feature.properties.centroid_lat, feature.properties.centroid_long];
    }
    
    // Fallback: try to calculate center of the feature
    if (feature.geometry) {
      try {
        const bounds = L.geoJSON(feature).getBounds();
        return bounds.getCenter();
      } catch (error) {
        console.error('Error calculating feature center:', error);
      }
    }
    
    // Final fallback: use map center
    return initialCenter;
  };


  // =====================================================================
  // Updateing Map Logic
  // =====================================================================

  const tractStyle = React.useCallback((feature) => {
    if (!metricsData) return {};

    return TractStyler({
      feature,
      primaryMetric,
      selectedTractId,
      metricsData,
      selectedMetricType,
      opacityColorGradient,
      lineColor,
      opacitySelected,
      opacityUnselected,
      selectedColorGradient,
      mapColors,
      getMetricTypeIndex,
      customRanges: tractColorRangeValues
    });
  }, [
    primaryMetric?.id,
    selectedTractId,
    selectedMetricType,
    selectedColorGradient,
    opacityColorGradient,
    opacitySelected,
    opacityUnselected,
    metricsData?.headers,
    tractColorRangeValues,
    lineColor,
    mapColors,
    getMetricTypeIndex
  ]);


  const colorTractsByMetric = React.useCallback((geoJson, metric) => {
    if (!geoJson || !metric) return;

    // Directly update layer styles without re-rendering
    if (geoJsonLayerRef.current) {
      geoJsonLayerRef.current.eachLayer((layer) => {
        const feature = layer.feature;
        if (viewMode === 'tracts') {
          layer.setStyle(tractStyle(feature));
        }
      });
    }
  }, [tractStyle, viewMode]);


  // Update the onEachTract function to handle the new data structure
  const onEachTract = (feature, layer) => {
    // Check if we are in tract mode
    if (viewMode !== 'tracts') return; // Exit if not in tract mode

    layer.on({
      click: (e) => {
        L.DomEvent.stopPropagation(e);
        
        // If clicking the same tract, deselect it
        if (selectedTractId === feature.properties.GEOID) {
          setSelectedTractId(null);
          setSelectedFeature(null);
          colorTractsByMetric(tractGeoJson, primaryMetric);
          return;
        }
        
        setSelectedTractId(feature.properties.GEOID);
        
        // Update the feature's metrics with current data
        const countyId = feature.properties.COUNTYFP;
        const tractId = feature.properties.TRACTCE;
        const metricValues = metricsData.data[countyId]?.[tractId] || [];
        
        // Create metrics object from current data
        const metrics = {};
        metricsData.headers.forEach((header, index) => {
          metrics[header] = metricValues[index];
        });
        
        // Update feature properties with new metrics
        const updatedFeature = {
          ...feature,
          properties: {
            ...feature.properties,
            metrics: metrics
          }
        };
        
        setSelectedFeature(updatedFeature);
        colorTractsByMetric(tractGeoJson, primaryMetric);
      },
    });
  };


  // Update useEffect for color range changes
  useEffect(() => {
    if (viewMode === 'tracts' && tractGeoJson && primaryMetric) {
      colorTractsByMetric(tractGeoJson, primaryMetric);
    }
  }, [tractColorRangeValues, selectedMetricType, opacityColorGradient, selectedColorGradient, colorTractsByMetric, viewMode, tractGeoJson, primaryMetric]);


// This function is used to style the counties
  const CountyStyle = React.useMemo(() => (feature) => {
    const isSelected = selectedFeatureId === feature.properties.GEOID;
    const countyId = feature.properties.GEOID.slice(-3);
    const countyData = metricsData.county_data[countyId];
    
    if (!primaryMetric || !countyData) {
      return {
        fillColor: '#ffffff',
        weight: isSelected ? 3 : 2,
        opacity: isSelected ? opacitySelected : opacityUnselected,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    // Get the index in headers that corresponds to the selected metric type
    const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[getMetricTypeIndex(selectedMetricType)]);
    const metricValue = countyData[metricIndex];
    
    if (!metricValue || isNaN(Number(metricValue)) || metricValue < 0) {
      return {
        fillColor: '#ffffff',
        weight: isSelected ? 3 : 2,
        opacity: isSelected ? opacitySelected : opacityUnselected,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    // Get the color gradient array
    const colorGradient = mapColors.list_of_5[selectedColorGradient];

    // Get all values for this metric to determine min/max
    const allValues = Object.values(metricsData.county_data)
      .map(county => Number(county[metricIndex]))
      .filter(val => !isNaN(val) && val !== null && val >= 0);
    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    // Function to get color based on value
    const getColor = (value, min, max) => {
      if (countyColorRangeValues) {
        // Use custom ranges if provided
        for (let i = 0; i < countyColorRangeValues.length - 1; i++) {
          if (value >= countyColorRangeValues[i] && value <= countyColorRangeValues[i + 1]) {
            return colorGradient[i];
          }
        }
        return colorGradient[0]; // Default to first color if value is below all ranges
      } else {
        // Calculate color index based on value's position between min and max
        const normalizedValue = (value - min) / (max - min);
        const colorIndex = Math.min(
          Math.floor(normalizedValue * (colorGradient.length - 1)),
          colorGradient.length - 1
        );
        return colorGradient[Math.max(0, colorIndex)];
      }
    };

    return {
      fillColor: getColor(Number(metricValue), min, max),
      weight: isSelected ? 3 : 2,
      opacity: isSelected ? opacitySelected : opacityUnselected,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  }, [selectedFeatureId, opacitySelected, opacityUnselected, lineColor, metricsData.county_data, primaryMetric, selectedMetricType, selectedColorGradient, countyColorRangeValues, opacityColorGradient]);


  const onEachCounty = React.useCallback((feature, layer) => {
    if (viewMode !== 'counties') return; 
  layer.on({
    click: (e) => {
      L.DomEvent.stopPropagation(e);
      setSelectedFeatureId(feature.properties.GEOID);
      setSelectedFeature(feature);
      setCurrentCountyId(feature.properties.GEOID.slice(-3));

      // Set selectedCounty to the county name
      setSelectedCounty(feature.properties.NAMELSAD); 
      
      // layer.setStyle(CountyStyle(feature));
    },
  });
  }, [CountyStyle]);


  const handleViewTracts = async (geoid) => {
    try {
      setIsLoadingTracts(true);
      const countyId = geoid.slice(-3);
      setCurrentCountyId(countyId);
      
      setTractColorRangeValues(null);  // Reset tract color ranges when switching to tract view
      
      // Get geometry data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/geometries/tracts_geometry/${selectedState}/${geoid}`
      );

      // Fetch geometry data
      const geometryResponse = await axios.get(response.data.geometry);
      const tractData = geometryResponse.data;      

      
      // Set tract data and update view mode
      setTractGeoJson(tractData);
      setViewMode('tracts');
      setSelectedFeature(null);

      // Force a re-render of the tract layer by updating the key
      if (geoJsonLayerRef.current) {
        const layer = geoJsonLayerRef.current;
        layer.clearLayers();
        layer.addData(tractData);
      }

    } catch (error) {
      console.error('Error fetching tract data:', error);
      // On error, make sure we stay in county view
      setViewMode('counties');
    } finally {
      setIsLoadingTracts(false);
    }
  };

  const handleSetPrimaryMetric = React.useCallback((metric) => {
    setPrimaryMetric(metric);
    setTractColorRangeValues(null);
    setCountyColorRangeValues(null);

    // Get and apply the default metric type for this metric
    const defaultType = metricsData.default_metric_types[metric.item_mapping[0].substring(0,9)];
    if (defaultType) {
      setSelectedMetricType(defaultType);
    } else {
      // If no default type is found, check if the metric has PM type
      const hasPM = metric.item_mapping.some(item => item.endsWith('PM'));
      if (!hasPM && selectedMetricType === 'PM') {
        // If no PM available and current type is PM, switch to Estimate
        setSelectedMetricType('E');
      }
    }
  }, [metricsData.default_metric_types, selectedMetricType]);

  const handleBackToCounties = () => {
    setTractGeoJson(null);
    setSelectedTractId(null);
    setViewMode('counties');
    setGeoJsonData(countyGeoJson);
    setSelectedFeature(null);
    setSelectedFeatureId(null);
    setCurrentCountyId(null);
    setTractColorRangeValues(null);
    setCountyColorRangeValues(null);
    if (mapRef.current) {
      mapRef.current.flyTo(initialCenter, mapSetup.initialZoom);
    }
  };


  // Update the useEffect for initial metrics
  useEffect(() => {
    if (initialMetrics?.length > 0) {
      fetchMetricsData(initialMetrics);
      // Set the first metric as primary metric
      setPrimaryMetric(initialMetrics[0]);
    }
  }, []);


  // Modify the handleDataProfileChange function
  const handleDataProfileChange = async (newMetrics) => {
    let updatedMetrics;
    
    if (typeof newMetrics === 'function') {
      // If newMetrics is a function, apply it to the current selectedMetrics
      updatedMetrics = newMetrics(selectedMetrics);
    } else {
      // If newMetrics is an array, use it directly
      updatedMetrics = Array.isArray(newMetrics) ? newMetrics : [];
    }

    // Find metrics that were added and removed
    const oldMetricIds = new Set(selectedMetrics.map(m => m.id));
    const newMetricIds = new Set(updatedMetrics.map(m => m.id));

    const addedMetrics = updatedMetrics.filter(m => !oldMetricIds.has(m.id));
    const removedMetricIds = [...oldMetricIds].filter(id => !newMetricIds.has(id));

    // Update the selected metrics state
    setSelectedMetrics(updatedMetrics);

    // Clear selections
    setSelectedTractId(null);
    setSelectedFeature(null);

    // If we have no metrics at all, reset everything
    if (updatedMetrics.length === 0) {
      setMetricsData({
        headers: [],
        data: {},
        county_data: {},
        default_metric_types: {}
      });
      return;
    }

    // If we have added metrics, fetch only the new ones
    if (addedMetrics.length > 0) {
      await fetchMetricsData(addedMetrics);
    }

    // If we have removed metrics, update the metrics data structure
    if (removedMetricIds.length > 0) {
      setMetricsData(prevData => {
        // Get all header indices that need to be removed
        const headerIndicesToRemove = new Set();
        removedMetricIds.forEach(id => {
          const removedMetric = selectedMetrics.find(m => m.id === id);
          if (removedMetric) {
            removedMetric.item_mapping.forEach(header => {
              const index = prevData.headers.indexOf(header);
              if (index !== -1) {
                headerIndicesToRemove.add(index);
              }
            });
          }
        });

        // Create new headers array excluding removed headers
        const newHeaders = prevData.headers.filter((_, index) => !headerIndicesToRemove.has(index));

        // Create mapping from old indices to new indices
        const indexMapping = prevData.headers.map((_, index) => 
          headerIndicesToRemove.has(index) ? -1 : 
          prevData.headers.slice(0, index).filter((_h, i) => !headerIndicesToRemove.has(i)).length
        );

        // Update county data
        const newCountyData = {};
        Object.entries(prevData.county_data).forEach(([countyId, values]) => {
          newCountyData[countyId] = values
            .filter((_, index) => !headerIndicesToRemove.has(index))
            .map((value, index) => value);
        });

        // Update tract data
        const newData = {};
        Object.entries(prevData.data).forEach(([countyId, tractData]) => {
          newData[countyId] = {};
          Object.entries(tractData).forEach(([tractId, values]) => {
            newData[countyId][tractId] = values
              .filter((_, index) => !headerIndicesToRemove.has(index))
              .map((value, index) => value);
          });
        });

        // Update default metric types
        const newDefaultMetricTypes = { ...prevData.default_metric_types };
        removedMetricIds.forEach(id => {
          delete newDefaultMetricTypes[id];
        });

        return {
          headers: newHeaders,
          data: newData,
          county_data: newCountyData,
          default_metric_types: newDefaultMetricTypes
        };
      });
    }

    // Update primary metric if needed
    if (!newMetricIds.has(primaryMetric?.id)) {
      if (updatedMetrics.length > 0) {
        handleSetPrimaryMetric(updatedMetrics[0]);
      } else {
        setPrimaryMetric(null);
      }
    }
  };


  // Update MapClickHandler to handle deselection
  const MapClickHandler = () => {
    const map = useMap();
    
    useEffect(() => {
      if (!map) return;

      const handleMapClick = (e) => {
        if (viewMode === 'tracts') {
          setSelectedTractId(null);
          setSelectedFeature(null);
          colorTractsByMetric(tractGeoJson, primaryMetric);
        }
      };

      map.on('click', handleMapClick);
      return () => map.off('click', handleMapClick);
    }, [map, viewMode, tractGeoJson, primaryMetric, colorTractsByMetric]);

    return null;
  };


  // Add this helper function to group metrics
  const groupMetrics = (metrics) => {
    const groups = {};
    
    // Get all base metric IDs from selectedMetrics (without E, M, PE, PM suffixes)
    const selectedBaseMetricIds = selectedMetrics.flatMap(m => 
      m.item_mapping.map(id => id.replace(/[EMP]+$/, ''))
    );
    
    Object.entries(metrics).forEach(([metricId, value]) => {
      // Get the base name without suffixes
      const baseName = metricId.replace(/[EMP]+$/, '');
      
      // Only process metrics whose base name is in selectedMetrics
      if (!selectedBaseMetricIds.includes(baseName)) {
        return;
      }

      if (!groups[baseName]) {
        // Find the metric from selectedMetrics that matches this base ID
        const metric = selectedMetrics.find(m => 
          m.item_mapping.some(id => id.startsWith(baseName))
        );
        
        groups[baseName] = {
          label: metric?.label || baseName,
          values: {}
        };
      }
      
      if (metricId.endsWith('PE')) groups[baseName].values.PE = value;
      else if (metricId.endsWith('PM')) groups[baseName].values.PM = value;
      else if (metricId.endsWith('E') && !metricId.endsWith('PE')) groups[baseName].values.E = value;
      else if (metricId.endsWith('M') && !metricId.endsWith('PM')) groups[baseName].values.M = value;
    });
    
    return groups;
  };

  // Update useEffect to watch for hierarchyType changes and set appropriate metrics
  useEffect(() => {
    const updateMetrics = async () => {
      setSelectedHierarchyType(hierarchyType);
      // If in demo mode and we have metricsByType, set the matching metrics
      if (demo && metricsByType && metricsByType[hierarchyType]) {
        const newMetrics = metricsByType[hierarchyType];
        setSelectedMetrics(newMetrics);
        
        // Reset metrics data
        setMetricsData({
          headers: [],
          data: {},
          county_data: {},
          default_metric_types: {}
        });

        // Fetch new metrics data
        if (newMetrics.length > 0) {
          await fetchMetricsData(newMetrics);
        }
      }
    };

    updateMetrics();
  }, [hierarchyType, demo, metricsByType]);

  // Add this useEffect to calculate min/max when primary metric or county changes
  useEffect(() => {
    
    // Ensure that we have the necessary data before proceeding
    if (!primaryMetric || !metricsData.data) {
      return;
    }
    
    const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[getMetricTypeIndex(selectedMetricType)]);
    if (metricIndex === -1) return;

    // Check if we are in county view
    if (viewMode === 'counties') {
      const allValues = Object.values(metricsData.county_data)
        .map(county => Number(county[metricIndex]))
        .filter(val => !isNaN(val) && val !== null && val >= 0);
      
      if (allValues.length === 0) return;

      const min = Math.min(...allValues);
      const max = Math.max(...allValues);
      
      setCountyMetricRange(prev => {
        if (prev.min === min && prev.max === max) return prev;
        return { min, max };
      });
    }

    // Check if we are in tract view
    if (viewMode === 'tracts') {
      const countyData = metricsData.data[currentCountyId];
      if (!countyData) return;
      const allValues = Object.values(countyData)
        .map(tractData => tractData[metricIndex])
        .filter(value => value !== null && value !== undefined && Number(value) >= 0)
        .map(Number);

      if (allValues.length === 0) return;

      const min = Math.min(...allValues);
      const max = Math.max(...allValues);
      
      setTractMetricRange(prev => {
        if (prev.min === min && prev.max === max) return prev;
        return { min, max };
      });
    }
  }, [primaryMetric, metricsData.county_data, metricsData.data, currentCountyId, metricsData.headers, selectedMetricType, getMetricTypeIndex, viewMode]);


  return (
    <div style={{
      position: "relative",
      width: "100%",
      height: demo ? "100%" : "100vh",
      overflow: "hidden",
      margin: 0,
      padding: 0
    }}>
      <LocationMetricDisplayBar 
        selectedState={selectedState}
        selectedFeature={selectedFeature}
        viewMode={viewMode}
        selectedCounty={selectedCounty}
        primaryMetric={primaryMetric}
        handleBackToCounties={handleBackToCounties}
        handleViewTracts={handleViewTracts}
        isPanelVisible={isPanelVisible}
      />

      <div style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}>
        {mapDefinitions && (  
          <MapContainer 
            key={`${selectedState}-${initialCenter[0]}-${initialCenter[1]}`}
            center={initialCenter}
            zoom={mapSetup.initialZoom} 
            maxBounds={mapSetup.maxBounds}
            maxBoundsViscosity={mapSetup.maxBoundsViscosity}
            minZoom={mapSetup.minZoom}
            maxZoom={mapSetup.maxZoom}
            style={{
              width: "100%",
              height: "100%"
            }}
            ref={mapRef}
            zoomControl={false}
            attributionControl={false}
          >
            <ZoomControl position="topright" />
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=8f074345-7c64-4b84-bedc-a74592e223c5`}
              minZoom={mapSetup.minZoom}
              maxZoom={mapSetup.maxZoom}
            />
            {/* County boundaries - show when in county view or while loading tracts */}
            {(viewMode === 'counties' || isLoadingTracts) && geoJsonData && (
              <GeoJSON 
                key={selectedFeatureId}
                data={geoJsonData} 
                style={CountyStyle}
                onEachFeature={onEachCounty}
              />
            )}
            
            {/* Tract boundaries */}
            {viewMode === 'tracts' && tractGeoJson && (
              <GeoJSON 
                ref={geoJsonLayerRef}
                key={`tracts-${currentCountyId}-${primaryMetric?.id}`}
                data={tractGeoJson}
                style={tractStyle}
                onEachFeature={onEachTract}
              />
            )}
            {selectedFeature && (
              <Popup
                position={getPopupPosition(selectedFeature)}
                maxHeight={400}
              >
                <div style={{ maxWidth: '300px' }}>
                  <h3 style={{ 
                    margin: '0 0 15px 0', 
                    fontSize: '1.2em',
                    borderBottom: '2px solid #2196f3',
                    paddingBottom: '8px',
                    color: '#1976d2'
                  }}>
                    {viewMode === 'counties' 
                      ? selectedFeature.properties.NAMELSAD 
                      : `Tract ${selectedFeature.properties.GEOIDFQ}`
                    }
                  </h3>
                  
                  {/* Metrics Display */}
                  {viewMode === 'counties' && selectedFeature && (
                    <Box sx={{ mt: 2 }}>
                      {primaryMetric && metricsData.county_data[selectedFeature.properties.GEOID.slice(-3)] && (
                        <MetricDisplay
                          key={primaryMetric.id}
                          label={primaryMetric.label}
                          values={{
                            E: metricsData.county_data[selectedFeature.properties.GEOID.slice(-3)][metricsData.headers.indexOf(primaryMetric.item_mapping[0])],
                            M: metricsData.county_data[selectedFeature.properties.GEOID.slice(-3)][metricsData.headers.indexOf(primaryMetric.item_mapping[1])],
                            PE: metricsData.county_data[selectedFeature.properties.GEOID.slice(-3)][metricsData.headers.indexOf(primaryMetric.item_mapping[2])],
                            PM: metricsData.county_data[selectedFeature.properties.GEOID.slice(-3)][metricsData.headers.indexOf(primaryMetric.item_mapping[3])]
                          }}
                          selectedType={selectedMetricType}
                        />
                      )}
                    </Box>
                  )}
                  {viewMode === 'tracts' && selectedFeature.properties.metrics && (
                    <Box sx={{ mt: 2 }}>
                      {Object.entries(groupMetrics(selectedFeature.properties.metrics)).map(([baseName, group]) => (
                        <MetricDisplay
                          key={baseName}
                          label={group.label}
                          values={group.values}
                          selectedType={selectedMetricType}
                        />
                      ))}
                    </Box>
                  )}

                  {/* View Tracts Button */}
                  {viewMode === 'counties' && (
                    <button 
                      onClick={() => handleViewTracts(selectedFeature.properties.GEOID)}
                      disabled={isLoadingTracts}
                      style={{
                        marginTop: '15px',
                        padding: '10px',
                        backgroundColor: isLoadingTracts ? '#bdbdbd' : '#2196f3',
                        color: 'white',
                        border: 'none',
                        borderRadius: '6px',
                        cursor: isLoadingTracts ? 'not-allowed' : 'pointer',
                        width: '100%',
                        fontWeight: '600',
                        transition: 'background-color 0.2s ease',
                        boxShadow: '0 2px 4px rgba(33,150,243,0.2)',
                        ':hover': {
                          backgroundColor: isLoadingTracts ? '#bdbdbd' : '#1976d2'
                        }
                      }}
                    >
                      {isLoadingTracts ? 'Loading Tracts...' : 'View Tracts'}
                    </button>
                  )}
                </div>
              </Popup>
            )}
            <MapClickHandler />
          </MapContainer>
        )}
        {error && <div style={{ position: 'absolute', zIndex: 2, backgroundColor: 'white', padding: '10px' }}>Error: {error}</div>}
      </div>

      <div style={{
        position: "absolute",
        left: isPanelVisible ? "0" : "-400px",
        top: "0",
        height: "100%",
        width: "400px",
        backgroundColor: "white",
        padding: "20px",
        boxSizing: "border-box",
        border: '1px solid #ccc',
        borderRadius: '0 5px 5px 0',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        transition: "left 0.3s ease-in-out",
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <button
          onClick={togglePanelVisibility}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '5px 10px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 1
          }}
        >
          Hide Panel
        </button>

        <h2>Control Panel</h2>

        <FormControl 
          fullWidth 
          sx={{ 
            marginBottom: 2,
            flexShrink: 0,
            '& .MuiInputBase-root': {
              fontSize: '1.1rem',
              minHeight: '36px'
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.1rem'
            },
            '& .MuiMenuItem-root': {
              fontSize: '1.1rem',
              padding: '12px 16px'
            }
          }}
        >
          <InputLabel id="hierarchy-type-label">Data Profile</InputLabel>
          <Select
            labelId="hierarchy-type-label"
            id="hierarchy-type-select"
            value={selectedHierarchyType}
            label="Data Profile"
            onChange={async (e) => {
              const newType = e.target.value;
              setSelectedHierarchyType(newType);
              
              // Set the metrics for the new profile type if in demo mode
              if (demo && metricsByType && metricsByType[newType]) {
                const newMetrics = metricsByType[newType];
                setSelectedMetrics(newMetrics);
                
                // Reset metrics data
                setMetricsData({
                  headers: [],
                  data: {},
                  county_data: {},
                  default_metric_types: {}
                });

                // Fetch new metrics data
                if (newMetrics.length > 0) {
                  await fetchMetricsData(newMetrics);
                }
              }
            }}
          >
            <MenuItem value="DP02">Social Characteristics</MenuItem>
            <MenuItem value="DP03">Economic Characteristics</MenuItem>
            <MenuItem value="DP04">Housing Characteristics</MenuItem>
            <MenuItem value="DP05">Demographic Characteristics</MenuItem>
          </Select>
        </FormControl>

        <div style={{
          flexGrow: 1,
          overflowY: 'auto',
          marginRight: '-20px',
          paddingRight: '20px',
          marginLeft: '-20px',
          paddingLeft: '20px',
        }}>
          <MetricSelection 
            showMetricsSelection={showMetricsSelection}
            setShowMetricsSelection={setShowMetricsSelection}
            MetricHierarchy={MetricHierarchy}
            expandedCategories={expandedCategories}
            setExpandedCategories={setExpandedCategories}
            handleDataProfileChange={handleDataProfileChange}
            selectedMetrics={selectedMetrics}
            primaryMetric={primaryMetric}
            handleSetPrimaryMetric={handleSetPrimaryMetric}
          />
            {/* Back to Counties button */}
            {viewMode === 'tracts' && (
            <button
              onClick={handleBackToCounties}
              style={{
                marginTop: '10px',
                marginBottom: '20px',
                padding: '8px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                width: '100%'
              }}
            >
              Back to Counties
            </button>
          )}
        <MetricTypeSelector
          selectedType={selectedMetricType}
          onChange={(type) => {
            setSelectedMetricType(type);
            if (viewMode === 'tracts') {
              setTractColorRangeValues(null);
            } else if (viewMode === 'counties') {
              setCountyColorRangeValues(null);
            }
          }}
        />
          <ColorControls 
            selectedColorGradient={selectedColorGradient}
            setSelectedColorGradient={setSelectedColorGradient}
            opacityUnselected={opacityUnselected}
            setOpacityUnselected={setOpacityUnselected}
            opacitySelected={opacitySelected}
            setOpacitySelected={setOpacitySelected}
            opacityColorGradient={opacityColorGradient}
            setOpacityColorGradient={setOpacityColorGradient}
            isColorControlsCollapsed={isColorControlsCollapsed}
            setIsColorControlsCollapsed={setIsColorControlsCollapsed}
          />
        
        </div>
      </div>

      {/* Show Panel button */}
      <button
        onClick={togglePanelVisibility}
        style={{
          position: 'absolute',
          top: '58px',
          left: isPanelVisible ? '-200px' : '10px',
          zIndex: 1001,
          padding: '5px 5px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: "left 0.3s ease-in-out"
        }}
      >
        Show Panel
      </button>

      {/* Return Home Link - Only show if not in demo mode */}
      {!demo && (
        <Link
          to="/account"
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            zIndex: 1001,
            padding: '8px 16px',
            backgroundColor: '#2196f3',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'background-color 0.3s ease',
            fontSize: '14px',
            fontWeight: 'bold',
            textDecoration: 'none',
            '&:hover': {
              backgroundColor: '#1976d2'
            }
          }}
        >
          Return Home
        </Link>
      )}

      {/* Add tutorial overlay */}
      {demo && (
        <TutorialOverlay 
          showTutorial={showTutorial}
          tutorialStep={tutorialStep}
          setTutorialStep={setTutorialStep}
          setShowTutorial={setShowTutorial}
        />
      )}

      {primaryMetric
      && ((viewMode === 'counties' && countyMetricRange.min !== null && countyMetricRange.max !== null) || 
        (viewMode === 'tracts' && tractMetricRange.min !== null && tractMetricRange.max !== null)) && 
        (
        <>
          <div
            onClick={() => setShowBoxPlot(!showBoxPlot)}
            style={{
              position: 'absolute',
              bottom: showBoxPlot ? '110px' : '10px',
              transition: 'bottom 0.3s ease-in-out',
              right: isPanelVisible ? 'calc(50% + 200px)' : 'calc(50% + 400px)',
              padding: '4px 8px',
              backgroundColor:'#2196f3',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '0.875rem',
              display: 'block',
              margin: '0px auto 10px auto',
              zIndex: 1001,
            }}
          >
            {showBoxPlot ? 'Hide Distribution' : 'See Distribution'}
          </div>
          <div style={{
              position: 'absolute',
              bottom: '20px',
              left: isPanelVisible ? 'calc(50% + 200px)' : '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              maxWidth: '800px',
              zIndex: 1000,
              padding: '0px',
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              pointerEvents: 'auto',
              userSelect: 'none'
            }}>
            <div style={{
              marginBottom: showBoxPlot ? '200px' : '100px',
              transition: 'margin-bottom 0.3s ease-in-out'
            }}>
              <ColorRangeSlider 
                min={viewMode === 'tracts' ? tractMetricRange.min : countyMetricRange.min}
                max={viewMode === 'tracts' ? tractMetricRange.max : countyMetricRange.max}
                metricType={selectedMetricType}
                currentValues={viewMode === 'tracts' ? tractColorRangeValues : countyColorRangeValues}
                selectedColorGradient={selectedColorGradient}
                primaryMetricId={primaryMetric?.id || null}
                metricDisplayType={selectedMetricType}
                onChange={(values) => {
                  if (viewMode === 'tracts') {
                    setTractColorRangeValues(prev => {
                      if (!prev || !values || prev.length !== values.length) {
                        setSelectedTractId(null);
                        setSelectedFeature(null);
                        return values;
                      }
                      const hasChanged = values.some((val, idx) => prev[idx] !== val);
                      if (hasChanged) {
                        setSelectedTractId(null);
                        setSelectedFeature(null);
                        return values;
                      }
                      return prev;
                    });
                  } else {
                    setCountyColorRangeValues(prev => {
                      if (!prev || !values || prev.length !== values.length) {
                        setCurrentCountyId(null);
                        setSelectedFeature(null);
                        return values;
                      }
                      const hasChanged = values.some((val, idx) => prev[idx] !== val);
                      if (hasChanged) {
                        setCurrentCountyId(null);
                        setSelectedFeature(null);
                        return values;
                      }
                      return prev;
                    });
                  }
                }}
              />
            </div>

            {showBoxPlot && (
              <MetricBoxPlot 
                metricsData={viewMode === 'tracts' ? {
                  headers: metricsData.headers,
                  data: { [currentCountyId]: metricsData.data[currentCountyId] || {} },
                  viewMode: 'tracts'
                } : {
                  headers: metricsData.headers,
                  data: metricsData.county_data,
                  viewMode: 'counties'
                }}
                primaryMetric={primaryMetric}
                selectedMetricType={selectedMetricType}
              />
            )}
          </div>
        </>
      )}

    </div>
  );
};

// Add prop types for type checking
StateMap.propTypes = {
  initialState: PropTypes.string,
  initialMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      item_mapping: PropTypes.array
    })
  ),
  hierarchyType: PropTypes.string,
  demo: PropTypes.bool,
  metricsByType: PropTypes.object
};

export default StateMap;

