import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import TableRow from './TableRow';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tooltipStyles } from './utils/tooltipStyles';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Add, KeyboardArrowRight } from '@mui/icons-material';

const MetricSelection = ({
  metricsModified,
  handleReload,
  showMetricsSelection,
  setShowMetricsSelection,
  MetricHierarchy,
  expandedCategories,
  setExpandedCategories,
  handleDataProfileChange,
  selectedMetrics,
  primaryMetric,
  handleSetPrimaryMetric
}) => {
  // Add new state for Selected Metrics dropdown
  const [showSelectedMetrics, setShowSelectedMetrics] = React.useState(true);
    // Helper function to get all children for an item
  const getChildren = (itemId) => {
    return Object.values(MetricHierarchy).filter(item => item.parent === itemId);
  };
  return (
    <div style={{ 
      marginTop: '5px',
      padding: '10px',
      backgroundColor: 'white',
      borderRadius: '5px',
      border: '1px solid #ddd'
    }}>
      {/* Add Metrics Selection Header */}
      <div 
        onClick={() => setShowMetricsSelection(!showMetricsSelection)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '8px',
          backgroundColor: 'white',
          borderRadius: '4px',
          border: '1px solid #ddd'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TuneIcon sx={{ marginRight: '8px', color: '#666' }} />
          <h3 style={{ margin: 0, fontSize: '18px' }}>Available Metrics</h3>
          <Tooltip 
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px', flexWrap: 'wrap' }}>
                <p>Click the <KeyboardArrowRight sx={{ 
                  color: '#000000',
                  fontSize: '36px'
                }} /> to drop down and explore the metrics. Then click the <Add sx={{
                  color: '#2e7d32',
                  fontSize: '36px'
                }} /> to add the metric to your <strong>Selected Metrics</strong>.
                </p>
              </div>
            }
            arrow 
            placement="right"
            componentsProps={{
              tooltip: { sx: tooltipStyles.tooltip },
              arrow: { sx: tooltipStyles.arrow }
            }}
          >
            <HelpOutlineIcon 
              sx={{ 
                marginLeft: '8px', 
                color: '#FFA500',
                fontSize: '18px',
                cursor: 'help'
              }} 
            />
          </Tooltip>
        </div>
        <KeyboardArrowDownIcon 
          sx={{ 
            color: '#666',
            transform: showMetricsSelection ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'transform 0.3s ease-in-out'
          }} 
        />
      </div>

      {/* Metrics Selection Content */}
      <div style={{
        maxHeight: showMetricsSelection ? '400px' : '0',
        overflowY: showMetricsSelection ? 'auto' : 'hidden',
        overflowX: 'hidden',
        transition: 'all 0.3s ease-in-out',
        marginBottom: showMetricsSelection ? '20px' : '0',
        opacity: showMetricsSelection ? 1 : 0,
        transform: showMetricsSelection ? 'translateY(0)' : 'translateY(-10px)',
      }}>
        {MetricHierarchy && (
          <div style={{ 
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '5px',
            transition: 'all 0.3s ease-in-out',
          }}>
            <table style={{ 
              width: '100%',
              borderCollapse: 'collapse'
            }}>
              <tbody>
                {Object.values(MetricHierarchy)
                  .filter(item => !item.parent)
                  .map(item => (
                    <TableRow 
                      key={item.id} 
                      item={item}
                      expandedCategories={expandedCategories}
                      setExpandedCategories={setExpandedCategories}
                      selectedMetrics={selectedMetrics}
                      setSelectedMetrics={handleDataProfileChange}
                      getChildren={getChildren}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Warning Banner - moved to bottom */}
      {metricsModified && (
        <div style={{
          backgroundColor: '#fff3e0',
          color: '#e65100',
          padding: '8px 12px',
          borderRadius: '4px',
          marginTop: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '0.9rem'
        }}>
          <span>Metrics have been modified. Click to update map data.</span>
          <button
            onClick={handleReload}
            style={{
              backgroundColor: '#ff9800',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '4px 8px',
              cursor: 'pointer',
              fontSize: '0.8rem'
            }}
          >
            Update
          </button>
        </div>
      )}
        {/* Selected Metrics Header */}
        <div 
          onClick={() => setShowSelectedMetrics(!showSelectedMetrics)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            padding: '8px',
            backgroundColor: 'white',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TuneIcon sx={{ marginRight: '8px', color: '#666' }} />
            <h3 style={{ margin: 0, fontSize: '18px' }}>Selected Metrics</h3>
            <Tooltip 
              title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px', flexWrap: 'wrap' }}>
                  <p>Click the <FormatColorFillIcon sx={{ 
                    color: '#4CAF50',
                    fontSize: '36px'
                  }} /> to display the metric on the map, or the <CloseIcon sx={{
                    color: '#f44336',
                    fontSize: '36px'
                  }} /> to remove the metric from <strong>Selected Metrics</strong>.</p>
                </div>
              }
              arrow 
              placement="right"
              componentsProps={{
                tooltip: { sx: tooltipStyles.tooltip },
                arrow: { sx: tooltipStyles.arrow }
              }}
            >
              <HelpOutlineIcon 
                sx={{ 
                  marginLeft: '8px', 
                  color: '#FFA500',
                  fontSize: '18px',
                  cursor: 'help'
                }} 
              />
            </Tooltip>
          </div>
          <KeyboardArrowDownIcon 
            sx={{ 
              color: '#666',
              transform: showSelectedMetrics ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 0.3s ease-in-out'
            }} 
          />
        </div>
        
        {/* Selected Metrics Content */}
        <div style={{
          maxHeight: showSelectedMetrics ? '400px' : '0',
          overflowY: showSelectedMetrics ? 'auto' : 'hidden',
          overflowX: 'hidden',
          transition: 'all 0.3s ease-in-out',
          marginBottom: showSelectedMetrics ? '20px' : '0',
          opacity: showSelectedMetrics ? 1 : 0,
          transform: showSelectedMetrics ? 'translateY(0)' : 'translateY(-10px)',
          backgroundColor: 'white',
          border: '1px solid #ddd',
          borderRadius: '4px',
        }}>
          {selectedMetrics.length === 0 ? (
            <div style={{
              padding: '5px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <p style={{
                fontSize: '18px',
                margin: '0 0 2px 0'
              }}>
                No metrics selected
              </p>
              <p style={{
                fontSize: '14px',
              }}>
                Select from <strong>Available Metrics</strong> above
              </p>
            </div>
          ) : (
            <div>
              {selectedMetrics.map((metric, index) => (
                <React.Fragment key={metric.id}>
                  <div style={{ 
                    borderBottom: index < selectedMetrics.length - 1 ? '1px solid #e0e0e0' : 'none'
                  }}>
                    <Grid container spacing={0} style={{ padding: '8px 16px' }}>
                      <Grid item xs={9}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '100%'
                        }}>
                          <span style={{ fontSize: '14px' }}>{metric.label}</span>
                        </div>
                      </Grid>
                      <Grid item xs={1.5}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%'
                        }}>
                          <Tooltip 
                            title={primaryMetric?.id === metric.id ? 'Primary' : 'Set as Map Display'} 
                            arrow 
                            placement="top"
                            componentsProps={{
                              tooltip: { sx: tooltipStyles.tooltip },
                              arrow: { sx: tooltipStyles.arrow }
                            }}
                          >
                            {primaryMetric?.id === metric.id ? (
                              <RadioButtonCheckedIcon 
                                fontSize="small"
                                onClick={() => handleSetPrimaryMetric(metric)}
                                sx={{
                                  cursor: 'pointer',
                                  color: '#2196f3',
                                  '&:hover': {
                                    backgroundColor: 'rgba(33, 150, 243, 0.1)'
                                  }
                                }}
                              />
                            ) : (
                              <FormatColorFillIcon 
                                fontSize="small"
                                onClick={() => handleSetPrimaryMetric(metric)}
                                sx={{
                                  cursor: 'pointer',
                                  color: '#4CAF50',
                                  '&:hover': {
                                    backgroundColor: 'rgba(76, 175, 80, 0.1)'
                                  }
                                }}
                              />
                            )}
                          </Tooltip>
                        </div>
                      </Grid>
                      <Grid item xs={1.5}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%'
                        }}>
                          <Tooltip 
                            title="Remove Metric" 
                            arrow 
                            placement="top"
                            componentsProps={{
                              tooltip: { sx: tooltipStyles.tooltip },
                              arrow: { sx: tooltipStyles.arrow }
                            }}
                          >
                            <CloseIcon 
                              fontSize="small"
                              onClick={() => handleDataProfileChange(prev => 
                                prev.filter(m => m.id !== metric.id)
                              )}
                              sx={{
                                cursor: 'pointer',
                                color: '#f44336',
                                '&:hover': {
                                  backgroundColor: 'rgba(244, 67, 54, 0.1)'
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    // </div>
  );
};

MetricSelection.propTypes = {
  metricsModified: PropTypes.bool.isRequired,
  handleReload: PropTypes.func.isRequired,
  showMetricsSelection: PropTypes.bool.isRequired,
  setShowMetricsSelection: PropTypes.func.isRequired,
  MetricHierarchy: PropTypes.object,
  expandedCategories: PropTypes.instanceOf(Set).isRequired,
  setExpandedCategories: PropTypes.func.isRequired,
  handleDataProfileChange: PropTypes.func.isRequired,
  selectedMetrics: PropTypes.array.isRequired,
  primaryMetric: PropTypes.object,
  handleSetPrimaryMetric: PropTypes.func.isRequired
};

export default MetricSelection; 