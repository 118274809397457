/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React,{ useEffect, useRef } from "react";
import Rellax from "rellax";
import Typed from "typed.js";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Information from "../sections/Information";
import Mission from "../sections/Mission";
import Featuring from "../sections/Featuring";
import Newsletter from "../sections/Newsletter";
import bgImage from "assets/images/usa_night.webp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function AboutUs() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["Let us help!"],
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 400,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
    <Helmet>
        <title>About Us - TerraGrasp</title>
        <meta name="description" content="Learn more about TerraGrasp and our mission to provide the best income data insights." />
        <meta property="og:title" content="About Us - TerraGrasp" />
        <meta property="og:description" content="Discover our mission and values at TerraGrasp." />
        <meta property="og:image" content={`${window.location.origin}/about_us_image.webp`} />
        <meta name="twitter:title" content="About Us - TerraGrasp" />
        <meta name="twitter:description" content="Learn about TerraGrasp's mission and values." />
        <meta name="twitter:image" content={`${window.location.origin}/about_us_image.webp`} />
    </Helmet>
    <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
            rgba(gradients.dark.main, 0.6),
            rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        placeItems: "center",
        display: "grid",
        }}
    >
        <Container>
        <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
        >
            <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                fontSize: size["3xl"],
                },
            })}
            >
            Don't be in the dark... <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                We&apos;re constantly trying to better support our current and new clients find the resources they need. If you&apos;re looking for something in particular or just have a quesiton feel free to send us a message. An expert will get back to you shortly!
            </MKTypography>
            <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}  to="/user/sign-up" component={Link}>
            create account
            </MKButton>
        </Grid>
        </Container>
    </MKBox>
    <Card
        sx={{
            p: 4,
            mx: 'auto',  // Center the card
            mt: -8,
            mb: 4,
            maxWidth: '120vh',  // Adjust this value to match the header's width
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
        }}
    >

        <Information />
        <Mission />
        <Featuring />
        <Newsletter 
            text={"Whether you're an industry veteran or new to the field, data-driven decisions will inevitably shape your business. Stay ahead of the curve with our newsletter."}
        />
    </Card>
    </>
  );
}

export default AboutUs;
